import { Autocomplete, Card, CircularProgress, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import { ListOwnTallyCompanies, ListOwnTallyCompaniesRes } from "src/entity/recon-entity/ReconInterfaces";

export const DEFAULT_COMPANY_ID = "default-company-id";

export const DEFAULT_BRANCH_CODE = "default-branch-code";

export type TallyOrSapCompanyId = string & NonNullable<unknown>;

export type StateDispatch<T> = React.Dispatch<React.SetStateAction<T>>;

export interface ListCompaniesRes {
  companies: CompanyInfo[];
  lastSelectedCompanyId: string;
}

export interface CompanyInfo {
  companyId: string;
  companyName: string;
}

export interface ListBranchesRes {
  lastSelectedbranch: BranchInfo;
  branches: BranchInfo[];
}

export interface BranchInfo {
  branchCode: string;
  branchName: string;
}

interface IntegratedDropDownProps {
  tallyCompanyNameSelect?: (e: any, option: ListOwnTallyCompanies) => void;
  AfterListOwnTallyCompanies?: (res: ListOwnTallyCompaniesRes) => void;
  companyNameSelect: (e: any, option: CompanyInfo) => void;
  AfterListCompanies?: (res: ListCompaniesRes) => void;
  branchNameSelect: (e: any, option: BranchInfo) => void;
  AfterListBranches?: (res: ListBranchesRes) => void;
  isDisabled?: boolean;
}

export const IntegratedDropDown = ({
  tallyCompanyNameSelect,
  companyNameSelect,
  branchNameSelect,
  ...props
}: IntegratedDropDownProps) => {
  const { actor } = useContext(userContext);

  // const [tallyCompaniesLoaded, setTallyCompaniesLoaded] = useState<boolean>(false);
  // const [listTallyCompanies, setListTallyCompanies] = useState<ListOwnTallyCompanies[]>([]);
  // const [lastTallyCompany, setLastTallyCompany] = useState<ListOwnTallyCompanies>(null);

  const [companiesLoaded, setCompaniesLoaded] = useState<boolean>(false);
  const [listCompanies, setListCompanies] = useState<CompanyInfo[]>([]);
  const [lastCompany, setLastCompany] = useState<CompanyInfo>(null);

  const [branchesLoaded, setBranchesLoaded] = useState<boolean>(true);
  const [listBranches, setListBranches] = useState<BranchInfo[]>([]);
  const [lastBranch, setLastBranch] = useState<BranchInfo>(null);

  // const optionsListOwnTallyCompanies = GetOptionsList(listTallyCompanies, "companyName");
  const optionsListCompanies = GetOptionsList(listCompanies, "companyName");
  const optionsListBranches = GetOptionsList(listBranches, "branchName");

  const _internalCompanyNameSelect = (e: any, option: CompanyInfo) => {
    if (option !== null) {
      if (e?.persist) e.persist();
      companyNameSelect && companyNameSelect(e, option);
      setLastCompany(option);
      if (actor.integration && actor.branchLevelReconcilation) {
        ListBranches(option.companyId);
      }
    }
  };

  const _internalBranchNameSelect = (e: any, option: BranchInfo) => {
    if (option !== null) {
      if (e?.persist) e.persist();
      branchNameSelect && branchNameSelect(e, option);
      setLastBranch(option);
    }
  };

  const _internalCompanySave = (e: any, option: CompanyInfo) => {
    // Reset Branch before selecting new company
    setLastBranch(null);
    branchNameSelect(e, null);

    _internalCompanyNameSelect(e, option);
    useFetch("/api/SaveSelectedCompany", "POST", {
      showSuccessToast: true,
      data: {
        companyId: option.companyId,
      },
      thenCallBack: (_res) => {},
    });
  };

  const _internalBranchSave = (e: any, option: BranchInfo) => {
    _internalBranchNameSelect(e, option);
    useFetch("/api/SaveSelectedBranch", "POST", {
      showSuccessToast: true,
      data: {
        companyId: lastCompany.companyId,
        branchCode: option.branchCode,
        branchName: option.branchName,
      },
      thenCallBack: (_res) => {},
    });
  };

  const ListCompanies = async () => {
    setCompaniesLoaded(false);
    useFetch<ListCompaniesRes>("/api/ListCompanies", "GET", {
      thenCallBack: (res) => {
        setListCompanies(res?.data?.companies);
        setCompaniesLoaded(true);
        const lastCompID = res?.data?.lastSelectedCompanyId;
        if (lastCompID) {
          const foundCompany = res.data.companies.find((c) => c.companyId === lastCompID);
          const foundLastCompany = {
            firstLetter: foundCompany?.companyName[0].toUpperCase(),
            ...foundCompany,
          };
          if (foundCompany) {
            setLastCompany(foundLastCompany);
            _internalCompanyNameSelect({}, foundLastCompany);
          }
        }
        if (props.AfterListCompanies) props.AfterListCompanies(res.data);
      },
    });
  };

  const ListBranches = async (company: string) => {
    setBranchesLoaded(false);
    await useFetch<ListBranchesRes>("/api/ListBranches", "GET", {
      config: {
        params: {
          companyId: company,
        },
      },
      thenCallBack: (res) => {
        setListBranches(res?.data?.branches);
        setBranchesLoaded(true);
        const lastBranch = res?.data?.lastSelectedbranch;
        if (lastBranch) {
          const foundBranch = res.data.branches.find((c) => c.branchCode === lastBranch.branchCode);
          const foundLastBranch = {
            firstLetter: foundBranch?.branchName[0]?.toUpperCase(),
            ...foundBranch,
          };
          if (foundBranch) {
            setLastBranch(foundLastBranch);
            _internalBranchNameSelect({}, foundLastBranch);
          } else {
            setLastBranch(null);
          }
        }
        if (props.AfterListBranches) props.AfterListBranches(res.data);
      },
    });
  };

  useEffect(() => {
    if (actor.integration) {
      ListCompanies();
    }
  }, [actor]);

  return (
    <>
      {/* Company Select Drop Down */}
      {actor.integration === true && (
        <div className="width_30_per d_block">
          <CommonAutoComplete<CompanyInfo>
            lastSelected={lastCompany}
            onChangeSelectFn={_internalCompanySave}
            optionsList={optionsListCompanies}
            optionLabelKey={"companyName"}
            loaded={companiesLoaded}
            label="Company Name"
            placeholder={"Select Company"}
            isDisabled={props.isDisabled}
          />
        </div>
      )}

      {/* Any Acc Software Branch Drop Down */}
      {actor.integration === true && actor.branchLevelReconcilation === true && (
        <div className="width_30_per d_block">
          <CommonAutoComplete<BranchInfo>
            lastSelected={lastBranch}
            onChangeSelectFn={_internalBranchSave}
            optionsList={optionsListBranches}
            optionLabelKey={"branchName"}
            loaded={branchesLoaded}
            label="Branch Name"
            placeholder="Select Branch"
          />
        </div>
      )}
    </>
  );
};

function GetOptionsList<T>(optionsList: T[], keyName: keyof T) {
  return optionsList.map((option) => {
    const firstLetter = (option[keyName][0] as string)?.toUpperCase();
    return {
      firstLetter,
      ...option,
    };
  });
}

export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

interface CommonAutoCompletePropsBase<T> {
  lastSelected: Partial<T>;
  onChangeSelectFn: (e: any, value: T) => void;
  optionsList: (T & { firstLetter: string })[];
  optionLabelKey: keyof T;
  loaded: boolean;
  label?: string;
  placeholder?: string;
  id?: string;
  isDisabled?: boolean;
}

type CommonAutoCompleteProps<T> = RequireAtLeastOne<CommonAutoCompletePropsBase<T>, "label" | "placeholder">;

type TypeWithFirstLetter = { firstLetter?: string; [k: string]: any };

export function CommonAutoComplete<T extends TypeWithFirstLetter>({
  lastSelected,
  onChangeSelectFn,
  optionsList,
  optionLabelKey,
  loaded,
  label,
  id,
  isDisabled = false,
}: CommonAutoCompleteProps<T>) {
  return (
    <Card sx={{ p: 0.8 }}>
      <Autocomplete
        sx={{
          width: "300px",
          backgroundColor: "#fff",
        }}
        disabled={isDisabled}
        value={lastSelected}
        size="small"
        onChange={(e, value) => onChangeSelectFn(e, value as T)}
        id={id || label}
        options={optionsList.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter)) || []}
        loading={!loaded}
        disableClearable={true}
        groupBy={(option) => option?.firstLetter}
        getOptionLabel={(option) => (option as T)[optionLabelKey] || ""}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Type Name"
            label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {!loaded ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Card>
  );
}

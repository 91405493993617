import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { EmailSendCount } from "src/entity/recon-entity/ReconInterfaces";
import { SingleIssue } from "../BalanceConfirmationPortal/FinalPage";
import { StateDispatch } from "../CommonLegacy/ReplyEmail";
import { PartnerCommsContext } from "../Context/PartnerCommunicationContext";
import { RaisedIssueBc } from "../LedgerRequestPortal/CommonComponents";

interface PartnerRequestsProps {
  open: boolean;
  setOpen: StateDispatch<boolean>;
  allIssues: { businessPartnerName: string; vendorCode: string; issues: RaisedIssueBc[] }[];
  markIssueResolved?: (issueId: number) => Promise<{
    success: boolean;
  }>;
  setSendSoleOrBulkMail?: (value) => void;
  setWithoutTemplateCheck?: (value) => void;
}

const PartnerRequests = (props: PartnerRequestsProps) => {
  const [index, setIndex] = useState<number>(null);
  const [, setCurrentBp] = useState("");
  const [currentIssues, setCurrentIssues] = useState<RaisedIssueBc[]>([]);

  // Email States BEGIN
  const {
    setOpenBcBetaMailing,
    setOpenReplyEmail,

    emailTemplates,
    setEmailTemplateContent,
    setEmailBusinessPartnerId,
  } = useContext(PartnerCommsContext);

  const handleClick = (_ev: any, num: number, issue: { businessPartnerName: string; issues: RaisedIssueBc[] }) => {
    setCurrentBp(issue.businessPartnerName);
    setIndex(num);
    setCurrentIssues(issue.issues.sort((a, b) => a.issueId - b.issueId));
  };

  useEffect(() => {
    if (props.allIssues && props.allIssues.length > 0) handleClick(null, 0, props.allIssues[0]);

    if (typeof index === "number" && props.allIssues && props.allIssues.length > 0) {
      handleClick(null, index, props.allIssues[index]);
    }
  }, [index, props.allIssues]);

  useEffect(() => {
    if (props.open && props.allIssues && props.allIssues.length > 0) {
      setIndex(0);
    } else if (!props.open) {
      setCurrentIssues([]);
    }
  }, [props.open]);

  const ReplyMail = (businessPartnerId: number, issue: RaisedIssueBc) => {
    // logic
    setEmailBusinessPartnerId(businessPartnerId);
    props.setSendSoleOrBulkMail(EmailSendCount.Sole);
    // getListUserEmailSetting();

    const foundTemplate = emailTemplates[0];

    setEmailTemplateContent(foundTemplate);

    props.setWithoutTemplateCheck(true);

    setEmailTemplateContent({
      ...foundTemplate,
      templateName: "Reply to Issue",
      emailBody: `<p><br></p>
      <p><br></p>
      <p>-----------------------------------------</p>
      <p><u>Response to the issue</u>: </p>
      <p>${issue.reason}</p>

      <p><u><br></u></p>
      <p><u>Raised by</u>:</p>
      <p>Name: ${issue.requestedBy}</p>
      <p>Email:&nbsp;${issue.emailId}</p>
      <p>Phone no:&nbsp;${issue.phoneNumber}</p>`,
    });
    setOpenBcBetaMailing(true);
    setOpenReplyEmail(true);
    props.setOpen(false);
    // close Menu
  };

  return (
    <>
      <Dialog open={props.open} maxWidth="md" fullWidth={true}>
        <Box className="d_flex" sx={{ overflowY: "hidden" }}>
          <List
            component="nav"
            sx={{
              minWidth: 280,
              borderRight: "1px solid #CBCBCB",
              background: "#EEE",
              overflow: "auto",
            }}
          >
            <ListItem sx={{ position: "sticky", top: -8, background: "#eee", zIndex: 3 }}>
              <Typography variant="overline">Business Partners</Typography>
            </ListItem>
            {props.allIssues?.map((allIssue, _index) => (
              <ListItemButton
                key={allIssue.businessPartnerName}
                selected={index === _index}
                onClick={(event) => handleClick(event, _index, allIssue)}
                sx={{
                  "&.Mui-selected": {
                    color: "#fff",
                    backgroundColor: "#531c4c !important",
                  },
                  "&:hover": {
                    background: "rgba(83,28,76,0.5)",
                  },
                }}
              >
                <ListItemText
                  primary={allIssue.businessPartnerName}
                  secondary={allIssue.vendorCode ? allIssue.vendorCode : " "}
                />
              </ListItemButton>
            ))}
          </List>
          <Box className="dialog_right" sx={{ width: "100%" }}>
            <Box className="dialog_header space_between" alignItems="center" pr={1}>
              <DialogTitle>Partner Requests</DialogTitle>
              <IconButton
                onClick={() => {
                  props.setOpen(false);
                }}
              >
                <Close />
              </IconButton>
            </Box>
            <DialogContent
              sx={{ display: "flex", flexFlow: "column", gap: 0.5, minHeight: 300, height: "calc(100% - 64px)" }}
            >
              <Stack gap={2} className="Issues_container">
                {currentIssues?.map((issue: RaisedIssueBc) => (
                  <SingleIssue
                    key={issue.issueId}
                    bpName={issue.businessPartnerName}
                    {...issue}
                    markIssueResolved={props.markIssueResolved}
                    replyAction={
                      props.setSendSoleOrBulkMail
                        ? (_issueId: number) => {
                            ReplyMail(issue.businessPartnerId, issue);
                          }
                        : undefined
                    }
                  />
                ))}
              </Stack>
            </DialogContent>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default PartnerRequests;

import { Add } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useFetch from "src/Components/Common/useFetch";
import { CommsType } from "../../BalanceConfirmationBeta/CommunicationTypes/CommunicationTypes";
import NewCommunicationType from "../../BalanceConfirmationBeta/CommunicationTypes/NewAndEditCommunicationType";
import { NdButtonOutlined } from "../../MsmePartnerPortal/CommonComponents";
import { StateDispatch } from "../CommonComponents";

export const ChooseCommsType = ({ setSelectedCommsTypeId }: { setSelectedCommsTypeId: StateDispatch<number> }) => {
  const [communicationTypes, setCommunicationTypes] = useState<CommsType[]>([]);

  const [commsTypeId, setCommsTypeId] = useState<number | undefined>(undefined);

  const [openNewComms, setOpenNewComms] = useState(false);

  const ListCommunicationTypes = async () => {
    return useFetch<{ data: CommsType[] }>("/api/CommunicationType/ListAllCommunicationTypes", "GET", {
      thenCallBack: (_res) => {
        if (_res.data?.data) setCommunicationTypes(_res.data?.data);
      },
    });
  };

  useEffect(() => {
    ListCommunicationTypes();
  }, []);

  useEffect(() => {
    setSelectedCommsTypeId(commsTypeId);
  }, [commsTypeId]);

  return (
    <>
      {/* how to center a div ? */}
      <Box className="center_align_ver_horiz">
        <Card sx={{ maxWidth: 900, width: "100%", boxShadow: 3 }}>
          <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
              <Typography variant="h6" component="div">
                Choose Communication Type
              </Typography>
              <NdButtonOutlined
                variant="outlined"
                startIcon={<Add />}
                size="small"
                onClick={() => {
                  setCommsTypeId(undefined);
                  setOpenNewComms(true);
                }}
              >
                NEW COMMUNICATION
              </NdButtonOutlined>
            </Box>
            <FormControl>
              <RadioGroup
                defaultValue={"bcf"}
                onChange={(_e, val) => {
                  setCommsTypeId(Number(val));
                }}
              >
                <Stack direction={"row"} flexWrap={"wrap"} gap={2} p={2}>
                  {communicationTypes.map((commType) => (
                    <Card
                      className="vertical_center_align"
                      key={commType.id}
                      variant="outlined"
                      sx={{ p: 2, boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.08)", width: 400 }}
                    >
                      <FormControlLabel value={commType.id} control={<Radio />} label={commType.name} />
                      <Button
                        variant="text"
                        size="small"
                        sx={{ color: "primary.main" }}
                        onClick={(_e) => {
                          setCommsTypeId(commType.id);
                          setOpenNewComms(true);
                        }}
                      >
                        VIEW
                      </Button>
                    </Card>
                  ))}
                </Stack>
              </RadioGroup>
            </FormControl>
          </CardContent>
        </Card>
      </Box>
      <NewCommunicationType
        open={openNewComms}
        setOpen={setOpenNewComms}
        communicationTypeId={commsTypeId}
        onClose={() => {
          ListCommunicationTypes();
        }}
      />
    </>
  );
};

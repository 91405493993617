import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { BulkMailStatusResponse } from "src/entity/recon-entity/ReconInterfaces";

export interface CounterState {
  //BC beta
  isSendingBulkMailsBCB: boolean;
  openBulkMailReportDialogBCB: boolean;
  emailResponseMsgBCB: string;
  mailFailedUsersBCB: BulkMailStatusResponse["listOfFailedUsers"];
  mailFailedWorkbookBCB: string;
  //Ledger Request Beta
  isSendingBulkMailsLRB: boolean;
  openBulkMailReportDialogLRB: boolean;
  emailResponseMsgLRB: string;
  mailFailedUsersLRB: BulkMailStatusResponse["listOfFailedUsers"];
  mailFailedWorkbookLRB: string;
  //MSME
  isSendingBulkMailsMSME: boolean;
  openBulkMailReportDialogMSME: boolean;
  emailResponseMsgMSME: string;
  mailFailedUsersMSME: BulkMailStatusResponse["listOfFailedUsers"];
  mailFailedWorkbookMSME: string;
  //BC
  isSendingBulkMailsBC: boolean;
  openBulkMailReportDialogBC: boolean;
  emailResponseMsgBC: string;
  mailFailedUsersBC: BulkMailStatusResponse["listOfFailedUsers"];
  mailFailedWorkbookBC: string;
  //Ledger Reques
  isSendingBulkMailsLR: boolean;
  openBulkMailReportDialogLR: boolean;
  emailResponseMsgLR: string;
  mailFailedUsersLR: BulkMailStatusResponse["listOfFailedUsers"];
  mailFailedWorkbookLR: string;
}

const initialState = {
  //BC Beta
  isSendingBulkMailsBCB: false,
  openBulkMailReportDialogBCB: false,
  emailResponseMsgBCB: "",
  mailFailedUsersBCB: [],
  mailFailedWorkbookBCB: "",
  //LR Beta
  isSendingBulkMailsLRB: false,
  openBulkMailReportDialogLRB: false,
  emailResponseMsgLRB: "",
  mailFailedUsersLRB: [],
  mailFailedWorkbookLRB: "",
  //MSME
  isSendingBulkMailsMSME: false,
  openBulkMailReportDialogMSME: false,
  emailResponseMsgMSME: "",
  mailFailedUsersMSME: [],
  mailFailedWorkbookMSME: "",
  //BC
  isSendingBulkMailsBC: false,
  openBulkMailReportDialogBC: false,
  emailResponseMsgBC: "",
  mailFailedUsersBC: [],
  mailFailedWorkbookBC: "",
  //LR
  isSendingBulkMailsLR: false,
  openBulkMailReportDialogLR: false,
  emailResponseMsgLR: "",
  mailFailedUsersLR: [],
  mailFailedWorkbookLR: "",
} satisfies CounterState as CounterState;

const counterSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    //BC Beta
    updateIsSendingBulkMailsBCB(state, action: PayloadAction<boolean>) {
      state.isSendingBulkMailsBCB = action.payload;
    },
    updateOpenBulkMailReportDialogBCB(state, action: PayloadAction<boolean>) {
      state.openBulkMailReportDialogBCB = action.payload;
    },
    updateEmailResponseMsgBCB(state, action: PayloadAction<string>) {
      state.emailResponseMsgBCB = action.payload;
    },
    updateMailFailedUsersBCB(state, action: PayloadAction<BulkMailStatusResponse["listOfFailedUsers"]>) {
      state.mailFailedUsersBCB = action.payload;
    },
    updateMailFailedWorkbookBCB(state, action: PayloadAction<string>) {
      state.mailFailedWorkbookBCB = action.payload;
    },
    // LR Beta
    updateIsSendingBulkMailsLRB(state, action: PayloadAction<boolean>) {
      state.isSendingBulkMailsLRB = action.payload;
    },
    updateOpenBulkMailReportDialogLRB(state, action: PayloadAction<boolean>) {
      state.openBulkMailReportDialogLRB = action.payload;
    },
    updateEmailResponseMsgLRB(state, action: PayloadAction<string>) {
      state.emailResponseMsgLRB = action.payload;
    },
    updateMailFailedUsersLRB(state, action: PayloadAction<BulkMailStatusResponse["listOfFailedUsers"]>) {
      state.mailFailedUsersLRB = action.payload;
    },
    updateMailFailedWorkbookLRB(state, action: PayloadAction<string>) {
      state.mailFailedWorkbookLRB = action.payload;
    },
    //MSME
    updateIsSendingBulkMailsMSME(state, action: PayloadAction<boolean>) {
      state.isSendingBulkMailsMSME = action.payload;
    },
    updateOpenBulkMailReportDialogMSME(state, action: PayloadAction<boolean>) {
      state.openBulkMailReportDialogMSME = action.payload;
    },
    updateEmailResponseMsgMSME(state, action: PayloadAction<string>) {
      state.emailResponseMsgMSME = action.payload;
    },
    updateMailFailedUsersMSME(state, action: PayloadAction<BulkMailStatusResponse["listOfFailedUsers"]>) {
      state.mailFailedUsersMSME = action.payload;
    },
    updateMailFailedWorkbookMSME(state, action: PayloadAction<string>) {
      state.mailFailedWorkbookMSME = action.payload;
    },
    //BC
    updateIsSendingBulkMailsBC(state, action: PayloadAction<boolean>) {
      state.isSendingBulkMailsBC = action.payload;
    },
    updateOpenBulkMailReportDialogBC(state, action: PayloadAction<boolean>) {
      state.openBulkMailReportDialogBC = action.payload;
    },
    updateEmailResponseMsgBC(state, action: PayloadAction<string>) {
      state.emailResponseMsgBC = action.payload;
    },
    updateMailFailedUsersBC(state, action: PayloadAction<BulkMailStatusResponse["listOfFailedUsers"]>) {
      state.mailFailedUsersBC = action.payload;
    },
    updateMailFailedWorkbookBC(state, action: PayloadAction<string>) {
      state.mailFailedWorkbookBC = action.payload;
    },
    // LR
    updateIsSendingBulkMailsLR(state, action: PayloadAction<boolean>) {
      state.isSendingBulkMailsLR = action.payload;
    },
    updateOpenBulkMailReportDialogLR(state, action: PayloadAction<boolean>) {
      state.openBulkMailReportDialogLR = action.payload;
    },
    updateEmailResponseMsgLR(state, action: PayloadAction<string>) {
      state.emailResponseMsgLR = action.payload;
    },
    updateMailFailedUsersLR(state, action: PayloadAction<BulkMailStatusResponse["listOfFailedUsers"]>) {
      state.mailFailedUsersLR = action.payload;
    },
    updateMailFailedWorkbookLR(state, action: PayloadAction<string>) {
      state.mailFailedWorkbookLR = action.payload;
    },
  },
});

export const {
  //BC Beta
  updateIsSendingBulkMailsBCB,
  updateOpenBulkMailReportDialogBCB,
  updateEmailResponseMsgBCB,
  updateMailFailedUsersBCB,
  updateMailFailedWorkbookBCB,
  //LR Beta
  updateIsSendingBulkMailsLRB,
  updateOpenBulkMailReportDialogLRB,
  updateEmailResponseMsgLRB,
  updateMailFailedUsersLRB,
  updateMailFailedWorkbookLRB,
  //MSME
  updateIsSendingBulkMailsMSME,
  updateOpenBulkMailReportDialogMSME,
  updateEmailResponseMsgMSME,
  updateMailFailedUsersMSME,
  updateMailFailedWorkbookMSME,
  //BC
  updateIsSendingBulkMailsBC,
  updateOpenBulkMailReportDialogBC,
  updateEmailResponseMsgBC,
  updateMailFailedUsersBC,
  updateMailFailedWorkbookBC,
  //LR
  updateIsSendingBulkMailsLR,
  updateOpenBulkMailReportDialogLR,
  updateEmailResponseMsgLR,
  updateMailFailedUsersLR,
  updateMailFailedWorkbookLR,
} = counterSlice.actions;
export default counterSlice.reducer;

import { Close, Preview } from "@mui/icons-material";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Buffer } from "buffer";
import React, { useContext, useEffect, useState } from "react";
import useFetch from "src/Components/Common/useFetch";
import { PDFTemplate, UseCaseType } from "src/entity/recon-entity/ReconInterfaces";
import { dynamicContentOptionsBeta as dynamicOrigBeta } from "src/Utils/PartnerCommunication";
import { BcBetaContext } from "../BalanceConfirmationBeta/BalanceConfirmationBeta";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID } from "../CommonLegacy/CommonComponents";
import { PartnerCommsContext } from "../Context/PartnerCommunicationContext";
import Editor from "../Editor/Editor";

const CreatePDFTemplate = ({ open, setOpen, ...props }) => {
  const [pdfTemplateName, setPdfTemplateName] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const [copyAlert, setCopyAlert] = useState<boolean>(false);

  const { pdfTemplates, dynamicContentOptions } = useContext(PartnerCommsContext);
  const { listUserEmailTemplates } = useContext(BcBetaContext);

  const handleClose = () => {
    setOpen(false);
    clearAllState();
    listUserEmailTemplates();
  };

  const clearAllState = () => {
    setPdfTemplateName("");
    setEditorContent("");
  };

  type InsertApiPayload = Omit<PDFTemplate, "templateId">;

  const dynamicContentOpts = [...dynamicOrigBeta];

  useEffect(() => {
    ListDynamicContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const savePDFTemplate = () => {
    useFetch("/api/partnerCommunication/AddPDFTemplates", "POST", {
      showSuccessToast: true,
      data: {
        templateName: pdfTemplateName,
        templateBody: editorContent,
        templateType: UseCaseType.balanceConfirmation,
        // existingTemplateId: existingTemplateId,
      } as InsertApiPayload,
      thenCallBack: (_res) => {
        handleClose();
        clearAllState();
      },
    });
  };

  const getPDFTemplateBody = (_templateId: number) => {
    return new Promise<{ templateBody: string }>((resolve, reject) => {
      useFetch<{ templateBody: string }>("/api/partnerCommunication/GetPdfTemplateBody", "POST", {
        showSuccessToast: true,
        data: {
          templateId: _templateId,
          templateType: UseCaseType.balanceConfirmation,
        },
        thenCallBack: (_res) => {
          if (_res.data.templateBody) {
            resolve({ templateBody: _res.data.templateBody });
          }
        },
        catchCallBack: reject,
        errorCallback: reject,
      });
    });
  };

  const ListDynamicContent = () => {
    useFetch<{ dynamicContents: string[] }>("/api/partnerCommunication/ListDynamicContent", "GET", {
      config: {
        params: {
          companyId: props.companyId || DEFAULT_COMPANY_ID,
          branchCode: props.branchCode || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: (res) => {
        dynamicContentOpts.push(...res.data.dynamicContents.map((_content) => `{${_content}}`));

        // setDynamicContentOptions(dynamicContentOpts);
      },
    });
  };

  const openPDFInNewTab = (pdfBuffer: Uint8Array | ArrayBuffer) => {
    /* your PDF buffer as Uint8Array or ArrayBuffer */
    // Create a Blob from the PDF buffer
    const blob = new Blob([pdfBuffer], { type: "application/pdf" });

    const dataUrl = URL.createObjectURL(blob);

    // Open a new tab and set the URL to the data URL
    const newTab = window.open();
    newTab.location.href = dataUrl;
  };

  const previewPdfTemplate = () => {
    useFetch<{ pdf: string }>("/api/partnerCommunication/PreviewPDFTemplate", "POST", {
      showSuccessToast: true,
      data: {
        templateBody: editorContent,
      },
      thenCallBack: (_res) => {
        openPDFInNewTab(Buffer.from(_res.data.pdf, "base64"));
      },
    });
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth={true}>
      <Box className="dialog_header space_between" alignItems="center" pr={1}>
        <DialogTitle component={"div"}>
          <Typography variant="h6">Create PDF Template</Typography>
        </DialogTitle>
        <IconButton
          onClick={() => {
            handleClose();
          }}
        >
          <Close />
        </IconButton>
      </Box>
      <DialogContent sx={{ p: 2 }}>
        <Stack sx={{ gap: 2 }}>
          <Box className="d_flex space_between" alignItems="center" flexWrap="wrap" gap={2}>
            <Box width={{ xs: "100%", sm: 300 }}>
              <TextField
                select
                fullWidth
                variant="outlined"
                label="Select Template"
                size="small"
                onChange={async (e) => {
                  const filteredSelectedTemplate = pdfTemplates.filter((item) => {
                    return item.templateName === e.target.value;
                  });

                  if (filteredSelectedTemplate.length === 0) {
                    setPdfTemplateName("");
                    setEditorContent("");
                    return;
                  }

                  const { templateId, templateName } = filteredSelectedTemplate[0];

                  const templateBody = await getPDFTemplateBody(templateId);

                  setPdfTemplateName(templateName);
                  setEditorContent(templateBody.templateBody);
                }}
              >
                {pdfTemplates.length > 0 &&
                  pdfTemplates.map((item, itemInd: number) => (
                    <MenuItem key={itemInd} value={item.templateName}>
                      {item.templateName}
                    </MenuItem>
                  ))}
                <MenuItem value="">Select Template</MenuItem>
              </TextField>
            </Box>
            <Button
              variant="outlined"
              color="success"
              startIcon={<Preview />}
              size="small"
              onClick={previewPdfTemplate}
            >
              PREVIEW PDF TEMPLATE
            </Button>
          </Box>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="PDF Template Name"
            size="small"
            value={pdfTemplateName}
            onChange={(e) => setPdfTemplateName(e.target.value)}
          />

          <div className="EditorBox">
            <Editor
              dynamicContent={editorContent}
              setDynamicContent={setEditorContent}
              selectedTemplateName={""}
              selectedEmailTemplateContent={null}
            />

            {/* <NewEditor
              content={selectedEmailTemplateContentForCreateEmailTemplate?.emailBody}
              setContent={setDynamicContent}
            /> */}

            <Box className="d_flex">
              <div style={{ height: 40 }} className="mb_10 flex_end">
                {copyAlert && (
                  <Alert severity="success" sx={{ width: 200, height: 48, position: "relative", top: -10 }}>
                    Copied!
                  </Alert>
                )}
              </div>
            </Box>

            <Box className="d_flex" gap={2}>
              {/* Dynamic Content */}
              <Autocomplete
                size="small"
                onChange={(_, value: any) => {
                  if (value !== null) {
                    navigator.clipboard.writeText(value);
                    // insertDynamiccontent(value);
                    setCopyAlert(true);
                    setTimeout(() => {
                      setCopyAlert(false);
                    }, 3000);
                  }
                }}
                sx={{ width: 200 }}
                options={dynamicContentOptions}
                //   value={dynamicContent}
                renderInput={(params) => (
                  <TextField {...params} label="Dynamic Content" placeholder="Dynamic Content" />
                )}
              />
            </Box>
          </div>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 2, backgroundColor: "#f1f1f1" }}>
        <Button
          className="theme_btn"
          variant="contained"
          color="primary"
          disabled={pdfTemplateName.length < 1}
          onClick={() => savePDFTemplate()}
        >
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreatePDFTemplate;

import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { Box, Dialog, Divider, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useFetch from "src/Components/Common/useFetch";
import { ActorTypesForRecon } from "src/entity/recon-entity/ReconInterfaces";
import arrow_right from "src/Graphics/AutomatedRecon/arrow_right.svg";
import { isOverFlowing } from "src/Utils/Common/CheckTextOverflow";
import { formatDate, formatDateTime24Hours } from "src/Utils/DateUtils";
import styles from "./TasksHistoryModal.module.scss";

export enum TICKET_HISTORY_FIELD {
  Ticket = "Ticket",
  Maker = "Maker",
  Checker = "Checker",
  Approver = "Approver",
  Status = "Status",
  Priority = "Priority",
  DueDate = "Due Date",
  Remarks = "Remarks",
  MailFollowUp = "Mail Follow Up",
  ConversationFollowUp = "Conversation Follow Up",
  Attachment = "Attachment",
  Response = "Response",
  Query = "Query",
  Mail = "Mail",
}
export enum TICKET_HISTORY_ACTION {
  Created = "Created",
  Updated = "Updated",
  Deleted = "Deleted",
  Uploaded = "Uploaded",
  Moved = "Moved",
  ClosedAndReset = "ClosedAndReset",
  Approved = "Approved",
  Resolved = "Resolved",
  Raised = "Raised",
  Received = "Received",
  Sent = "Sent",
}
export enum NAKAD_SERVICE {
  PartnerCommunication = "Partner Communication",
  Recon = "Recon",
  DisputeResolution = "Dispute Resolution",
}

interface TicketHistoryInterface {
  id: number;
  userEmail: string;
  userName: string;
  timestamp: string;
  actionOwnerId: number;
  field: string;
  action: string;
  oldValue: string;
  newValue: string;
  service: NAKAD_SERVICE;
  actionOwner: string;
}

export default function TasksHistoryModal(props) {
  const [ticketHistoryList, setTicketHistoryList] = useState<TicketHistoryInterface[]>([]);
  const [loadingTicketHistory, setLoadingTicketHistory] = useState<boolean>(false);

  const getTicketHistory = () => {
    setLoadingTicketHistory(true);
    useFetch<{ history: TicketHistoryInterface[] }>("/api/tickets/GetTicketHistory", "GET", {
      config: { params: { ticketId: props.taskId } },
      thenCallBack: (res) => {
        setTicketHistoryList(res.data.history || []);
        setLoadingTicketHistory(false);
      },
      catchCallBack: () => {
        setLoadingTicketHistory(false);
      },
    });
  };

  useEffect(() => {
    getTicketHistory();
  }, []);

  return (
    <Dialog
      open={props.showTasksHistoryModal}
      onClose={() => props.setShowTasksHistoryModal(false)}
      sx={{ "& .MuiDialog-paper": { borderRadius: "16px" } }}
    >
      <div className={styles.modal_container}>
        <header className={styles.modal_title}>
          <p
            className="fw_600 fs_18 textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
          >
            Task History for {props.businessPartnerName}
          </p>
          <ClearRoundedIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              props.setShowTasksHistoryModal(false);
            }}
          />
        </header>
        <section className={styles.modal_body}>
          {loadingTicketHistory ? (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Skeleton variant="rounded" width="100%" height={40} />
              <Skeleton variant="rounded" width="100%" height={40} />
              <Skeleton variant="rounded" width="100%" height={40} />
              <Skeleton variant="rounded" width="100%" height={40} />
              <Skeleton variant="rounded" width="100%" height={40} />
              <Skeleton variant="rounded" width="100%" height={40} />
              <Skeleton variant="rounded" width="100%" height={40} />
            </Box>
          ) : (
            ticketHistoryList.map((item) => {
              let oldValue = item.oldValue;
              let newValue = item.newValue;
              if (
                item.field === TICKET_HISTORY_FIELD.MailFollowUp ||
                item.field === TICKET_HISTORY_FIELD.ConversationFollowUp
              ) {
                const oldValueArray = item.oldValue?.split(",");
                if (oldValueArray.length === 1) oldValue = "0 Times";
                else if (oldValueArray.length === 2) {
                  const count = oldValueArray[0];
                  const timeFormat = formatDate(oldValueArray[1]);
                  oldValue = count.concat(", ", timeFormat);
                }
                const newValueArray = item.newValue?.split(",");
                if (newValueArray.length === 2) {
                  const count = newValueArray[0];
                  const timeFormat = formatDate(newValueArray[1]);
                  newValue = count.concat(", ", timeFormat);
                }
              }

              if (item.field === TICKET_HISTORY_FIELD.DueDate) {
                oldValue = oldValue ? formatDate(oldValue) : "Blank";
                newValue = newValue ? formatDate(newValue) : "Blank";
              }

              let actionType = "";
              if (item.actionOwner === ActorTypesForRecon.ReconUser) {
                if (item.action === TICKET_HISTORY_ACTION.Updated) actionType = "updated the";
                if (item.action === TICKET_HISTORY_ACTION.Deleted) actionType = "deleted the";
                if (item.action === TICKET_HISTORY_ACTION.Uploaded) actionType = "uploaded the";
                if (item.action === TICKET_HISTORY_ACTION.Created) actionType = "created the task";
                if (item.action === TICKET_HISTORY_ACTION.Moved) actionType = "moved task to recon";
                if (item.action === TICKET_HISTORY_ACTION.ClosedAndReset) actionType = "closed and reset the task";
                if (item.action === TICKET_HISTORY_ACTION.Approved) actionType = "approved the response";
                if (item.action === TICKET_HISTORY_ACTION.Resolved) actionType = "resolved the query";
                if (item.action === TICKET_HISTORY_ACTION.Sent) actionType = "sent the mail";
              } else if (item.actionOwner === ActorTypesForRecon.Partner) {
                if (item.action === TICKET_HISTORY_ACTION.Raised) actionType = "Query raised by";
                if (item.action === TICKET_HISTORY_ACTION.Received) actionType = "Response received by";
                if (item.action === TICKET_HISTORY_ACTION.Updated) actionType = "Response updated by";
              }

              return (
                <Box key={item.id}>
                  <Box sx={{ paddingY: 1 }}>
                    {/* this is for the first row of the history item
                    e.g. Mukul deleted the Attachment
                    e.g. Mukul updated the Due Date
                    e.g. Mukul created the task
                     */}
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                      {item.actionOwner === ActorTypesForRecon.ReconUser ? (
                        <p>
                          <span className="fs_14 fw_600">{item.userName}</span>
                          <span className="fs_14"> {actionType} </span>
                          <span className="fs_14 fw_600">
                            {item.action === TICKET_HISTORY_ACTION.Created ||
                            item.action === TICKET_HISTORY_ACTION.Moved ||
                            item.action === TICKET_HISTORY_ACTION.ClosedAndReset ||
                            item.action === TICKET_HISTORY_ACTION.Approved ||
                            item.action === TICKET_HISTORY_ACTION.Resolved ||
                            item.action === TICKET_HISTORY_ACTION.Sent
                              ? ""
                              : item.field}
                          </span>
                        </p>
                      ) : (
                        <p>
                          <span className="fs_14"> {actionType} </span>
                          <span className="fs_14 fw_600">{props.businessPartnerName}</span>
                        </p>
                      )}
                      <Typography variant="subtitle2" sx={{ fontWeight: "400", color: "rgba(0, 0, 0, 0.6)" }}>
                        {formatDateTime24Hours(item.timestamp)}{" "}
                      </Typography>
                    </Box>
                    {/* this is for the second row of the history item*/}
                    {item.field === TICKET_HISTORY_FIELD.Attachment ? (
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            fontWeight: "400",
                            fontSize: "12px",
                            color: "rgba(0, 0, 0, 0.6)",
                            alignSelf: "center",
                            wordBreak: "break-word",
                          }}
                        >
                          Filename:
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            marginLeft: 1,
                            fontWeight: "400",
                            fontSize: "12px",
                            alignSelf: "center",
                            wordBreak: "break-word",
                          }}
                        >
                          {newValue}
                        </Typography>
                      </Box>
                    ) : item.field === TICKET_HISTORY_FIELD.Checker ||
                      item.field === TICKET_HISTORY_FIELD.Approver ||
                      item.field === TICKET_HISTORY_FIELD.Maker ||
                      item.field === TICKET_HISTORY_FIELD.Status ||
                      item.field === TICKET_HISTORY_FIELD.DueDate ||
                      item.field === TICKET_HISTORY_FIELD.Priority ||
                      item.field === TICKET_HISTORY_FIELD.Remarks ||
                      item.field === TICKET_HISTORY_FIELD.ConversationFollowUp ||
                      item.field === TICKET_HISTORY_FIELD.MailFollowUp ? (
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            marginRight: 1,
                            fontWeight: "400",
                            fontSize: "12px",
                            color: "rgba(0, 0, 0, 0.6)",
                            alignSelf: "center",
                            wordBreak: "break-word",
                          }}
                        >
                          {oldValue === null || oldValue === undefined ? "Blank" : oldValue}
                        </Typography>
                        <img src={arrow_right} alt="arrow icon" />
                        <Typography
                          variant="subtitle2"
                          sx={{
                            marginLeft: 1,
                            fontWeight: "400",
                            fontSize: "12px",
                            alignSelf: "center",
                            wordBreak: "break-word",
                          }}
                        >
                          {newValue === null || newValue === undefined ? "Blank" : newValue}
                        </Typography>
                      </Box>
                    ) : null}
                  </Box>
                  <Divider />
                </Box>
              );
            })
          )}
        </section>
      </div>
    </Dialog>
  );
}

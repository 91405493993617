import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { Button, Grid, MenuItem, Select, TextField } from "@mui/material";
import { ColumnDef, Row } from "@tanstack/react-table";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import useFetch from "src/Components/Common/useFetch";
import CustomToast from "src/Components/CustomToast";
import Loader from "../../Common/Loader";
import BpDashboardTable from "../../ReactTable/BpDashboardTable";
import {
  DEFAULT_BRANCH_CODE,
  DEFAULT_COMPANY_ID,
  TallyOrSapCompanyId,
} from "../PartnerCommunication/CommonLegacy/CommonComponents";

type StateDispatch<T> = React.Dispatch<React.SetStateAction<T>>;

interface ListBpUsersProps {
  setOpenListBusinessPartnerUsers: StateDispatch<boolean>;
  storeRowOpenListBusinessPartnerUsers: React.MutableRefObject<Row<any>>;
  companyId: TallyOrSapCompanyId;
  branchCode: string;
}

type SelectVal = "NA" | "to" | "cc" | "bcc";

const ListBusinessPartnerUsers = (props: ListBpUsersProps) => {
  const storePartnerUserMasterData = useRef<any[]>([]);
  const [rowsDataPartnerUserMaster, setRowsDataPartnerUserMaster] = useState<any[]>([]);
  const [toggle, setToggle] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(true);
  const [storePartnerUserNameValue, setStorePartnerUserNameValue] = useState<any>(null);
  const storePartnerUserNameValueRef = useRef<any>(null);
  const storePartnerUserNameValueFlag = useRef<boolean>(false);
  // const [storePartnerNameValue, setStorePartnerNameValue] = useState<any>(null);
  const storePartnerNameValueRef = useRef<any>(null);
  const storePartnerIdValueRef = useRef<any>(null);
  const storePartnerNameValueFlag = useRef<boolean>(false);
  const [storeEmailValue, setStoreEmailValue] = useState<any>(null);
  const storeEmailValueRef = useRef<any>(null);
  const storeEmailValueFlag = useRef<boolean>(false);
  const [storeDesignationValue, setStoreDesignationValue] = useState<any>(null);
  const storeDesignationValueRef = useRef<any>(null);
  const storeDesignationValueFlag = useRef<boolean>(false);
  const [storeBalanceConfirmationValue, setStoreBalanceConfirmationValue] = useState<SelectVal>(null);
  const storeBalanceConfirmationValueRef = useRef<any>(null);
  const storeBalanceConfirmationValueFlag = useRef<boolean>(false);
  const [storeLedgerRequestValue, setStoreLedgerRequestValue] = useState<SelectVal>(null);
  const storeLedgerRequestValueRef = useRef<any>(null);
  const storeLedgerRequestValueFlag = useRef<boolean>(false);
  const [storePaymentAdviceValue, setStorePaymentAdviceValue] = useState<SelectVal>(null);
  const storePaymentAdviceValueRef = useRef<any>(null);
  const storePaymentAdviceValueFlag = useRef<boolean>(false);
  const [, setMsmeConfirmationValue] = useState<SelectVal>(null);
  const msmeConfirmationValueRef = useRef<string>(null);
  const msmeConfirmationValueFlag = useRef<boolean>(false);
  const [disableAddPartnerUser, setDisableAddPartnerUser] = useState<boolean>(false);

  const columnDefinitionPartnerUserList = useMemo(
    (): ColumnDef<any>[] => [
      {
        header: "Partner user name",
        accessorKey: "name",
        maxSize: 200,
        minSize: 200,
        size: 200,
        cell: ({ row }) => (
          <div>
            {row.original.isEdit ? (
              <TextField
                size="small"
                variant="outlined"
                // fullWidth={true}
                // label="Name"
                name="name"
                value={storePartnerUserNameValueFlag.current ? storePartnerUserNameValueRef.current : row.original.name}
                onClick={() => {
                  storePartnerUserNameValueFlag.current = true;
                  storePartnerUserNameValueRef.current = row.original.name;
                  setStorePartnerUserNameValue(row.original.name);
                }}
                onChange={(e) => {
                  setStorePartnerUserNameValue(e.target.value);
                  storePartnerUserNameValueRef.current = e.target.value;
                }}
              />
            ) : (
              <span>{row.original.name}</span>
            )}
          </div>
        ),
      },

      {
        header: "Mail ID",
        accessorKey: "email",
        maxSize: 300,
        minSize: 240,
        size: 300,
        cell: ({ row }) => (
          <div>
            {row.original.isEdit ? (
              <TextField
                size="small"
                variant="outlined"
                // fullWidth={true}
                // label="Name"
                // disabled={true}
                name="email"
                value={storeEmailValueFlag.current ? storeEmailValueRef.current : row.original.email}
                onClick={() => {
                  storeEmailValueFlag.current = true;
                  storeEmailValueRef.current = row.original.email;
                  setStoreEmailValue(row.original.email);
                }}
                onChange={(e) => {
                  setStoreEmailValue(e.target.value);
                  storeEmailValueRef.current = e.target.value;
                }}
              />
            ) : (
              <span>{row.original.email}</span>
            )}
          </div>
        ),
      },
      {
        header: "Designation",
        accessorKey: "designationName",
        maxSize: 100,
        minSize: 100,
        size: 100,
        cell: ({ row }) => (
          <div>
            {row.original.isEdit ? (
              <TextField
                size="small"
                variant="outlined"
                // fullWidth={true}
                // label="Name"
                name="designationName"
                value={
                  storeDesignationValueFlag.current ? storeDesignationValueRef.current : row.original.designationName
                }
                onChange={(e) => {
                  storeDesignationValueFlag.current = true;
                  setStoreDesignationValue(e.target.value);
                  storeDesignationValueRef.current = e.target.value;
                }}
              />
            ) : (
              <span>{row.original.designationName}</span>
            )}
          </div>
        ),
      },

      {
        header: "Balance Confirmation",
        accessorKey: "balanceConfirmation",
        maxSize: 150,
        minSize: 150,
        size: 150,
        cell: ({ row }) => (
          <div>
            {row.original.isEdit ? (
              <Select
                size="small"
                fullWidth={true}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="balanceConfirmation"
                value={
                  storeBalanceConfirmationValueFlag.current
                    ? storeBalanceConfirmationValueRef.current
                    : row.original.balanceConfirmation
                }
                onChange={(e) => {
                  setRowsDataPartnerUserMaster([...storePartnerUserMasterData.current]);
                  storeBalanceConfirmationValueFlag.current = true;
                  setStoreBalanceConfirmationValue(e.target.value);
                  storeBalanceConfirmationValueRef.current = e.target.value;
                }}
              >
                <MenuItem value={"NA"}>NA</MenuItem>
                <MenuItem value={"to"}>to</MenuItem>
                <MenuItem value={"cc"}>cc</MenuItem>
                <MenuItem value={"bcc"}>bcc</MenuItem>
              </Select>
            ) : (
              <span>{row.original.balanceConfirmation}</span>
            )}
          </div>
        ),
      },
      {
        header: "Ledger request",
        accessorKey: "ledgerRequest",
        maxSize: 100,
        minSize: 100,
        size: 100,
        cell: ({ row }) => (
          <div>
            {row.original.isEdit ? (
              <Select
                size="small"
                fullWidth={true}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="ledgerRequest"
                value={
                  storeLedgerRequestValueFlag.current ? storeLedgerRequestValueRef.current : row.original.ledgerRequest
                }
                onChange={(e) => {
                  setRowsDataPartnerUserMaster([...storePartnerUserMasterData.current]);
                  storeLedgerRequestValueFlag.current = true;
                  setStoreLedgerRequestValue(e.target.value);
                  storeLedgerRequestValueRef.current = e.target.value;
                }}
              >
                <MenuItem value={"NA"}>NA</MenuItem>
                <MenuItem value={"to"}>to</MenuItem>
                <MenuItem value={"cc"}>cc</MenuItem>
                <MenuItem value={"bcc"}>bcc</MenuItem>
              </Select>
            ) : (
              <span>{row.original.ledgerRequest}</span>
            )}
          </div>
        ),
      },
      {
        header: "Payment advice",
        accessorKey: "paymentAdvice",
        maxSize: 100,
        minSize: 100,
        size: 100,
        cell: ({ row }) => (
          <div>
            {row.original.isEdit ? (
              <Select
                size="small"
                fullWidth={true}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="paymentAdvice"
                value={
                  storePaymentAdviceValueFlag.current ? storePaymentAdviceValueRef.current : row.original.paymentAdvice
                }
                onChange={(e) => {
                  setRowsDataPartnerUserMaster([...storePartnerUserMasterData.current]);
                  storePaymentAdviceValueFlag.current = true;
                  setStorePaymentAdviceValue(e.target.value);
                  storePaymentAdviceValueRef.current = e.target.value;
                }}
              >
                <MenuItem value={"NA"}>NA</MenuItem>
                <MenuItem value={"to"}>to</MenuItem>
                <MenuItem value={"cc"}>cc</MenuItem>
                <MenuItem value={"bcc"}>bcc</MenuItem>
              </Select>
            ) : (
              <span>{row.original.paymentAdvice}</span>
            )}
          </div>
        ),
      },
      {
        header: "MSME Confirmation",
        accessorKey: "msmeConfirmation",
        maxSize: 100,
        minSize: 100,
        size: 100,
        cell: ({ row }) => (
          <div>
            {row.original.isEdit ? (
              <Select
                size="small"
                fullWidth={true}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="ledgerRequest"
                value={
                  msmeConfirmationValueFlag.current ? msmeConfirmationValueRef.current : row.original.msmeConfirmation
                }
                onChange={(e) => {
                  setRowsDataPartnerUserMaster([...storePartnerUserMasterData.current]);
                  msmeConfirmationValueFlag.current = true;
                  setMsmeConfirmationValue(e.target.value);
                  msmeConfirmationValueRef.current = e.target.value;
                }}
              >
                <MenuItem value={"NA"}>NA</MenuItem>
                <MenuItem value={"to"}>to</MenuItem>
                <MenuItem value={"cc"}>cc</MenuItem>
                <MenuItem value={"bcc"}>bcc</MenuItem>
              </Select>
            ) : (
              <span>{row.original.msmeConfirmation}</span>
            )}
          </div>
        ),
      },
      {
        header: "Actions",
        size: 100,
        columns: [
          {
            header: "",
            id: "edit",
            accessorKey: "businessPartnerId",
            maxSize: 50,
            minSize: 50,
            size: 50,
            enableSorting: false,
            cell: ({ row }) => (
              <div>
                {row.original.isEdit ? (
                  <span
                    className="cursor_pointer"
                    onClick={() => {
                      let emailSettingErr = false;
                      if (
                        storeLedgerRequestValueRef.current === "NA" &&
                        storeBalanceConfirmationValueRef.current === "NA" &&
                        storePaymentAdviceValueRef.current === "NA" &&
                        msmeConfirmationValueRef.current === "NA" &&
                        storeBalanceConfirmationValueFlag.current &&
                        storeLedgerRequestValueFlag.current &&
                        storePaymentAdviceValueFlag.current &&
                        msmeConfirmationValueFlag.current
                      ) {
                        emailSettingErr = true;
                      }
                      let partnerNameErr = false;
                      if (storePartnerNameValueFlag.current && storePartnerNameValueRef.current === null) {
                        partnerNameErr = true;
                      }
                      let emailErr = false;
                      if (
                        storeEmailValueFlag.current &&
                        !storeEmailValueRef.current.match("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$")
                      ) {
                        emailErr = true;
                      }

                      if (
                        !row.original.email.match("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$") &&
                        !storeEmailValueFlag.current
                      ) {
                        emailErr = true;
                      }
                      if (!partnerNameErr && !emailErr && !emailSettingErr) {
                        addNewPartnerUser(row);
                      } else {
                        if (emailErr) {
                          toast.error(<CustomToast message="please enter valid email" />);
                        } else if (partnerNameErr) {
                          toast.error(<CustomToast message="please select business partner" />);
                        } else if (emailSettingErr) {
                          toast.error(<CustomToast message="please select Email Setting" />);
                        }
                      }
                    }}
                  >
                    <SaveIcon />
                  </span>
                ) : (
                  <span
                    className="cursor_pointer"
                    onClick={() => {
                      storePartnerUserMasterData.current?.forEach((ele: any, eleInd: number) => {
                        if (eleInd === row.index) {
                          ele.isEdit = true;
                        } else {
                          ele.isEdit = false;
                        }
                        return;
                      });
                      setRowsDataPartnerUserMaster(storePartnerUserMasterData.current);
                      setDisableAddPartnerUser(true);
                      setToggle(!toggle);
                    }}
                  >
                    <EditIcon />
                  </span>
                )}
              </div>
            ),
          },
          {
            header: "",
            id: "delete",
            accessorKey: "businessPartnerId",
            maxSize: 50,
            minSize: 50,
            size: 50,
            enableSorting: false,
            cell: ({ row }) => (
              <div
                className="cursor_pointer"
                onClick={() => {
                  deleteBusinessPartnerUser(row);
                }}
              >
                <DeleteIcon />
              </div>
            ),
          },
        ],
      },
    ],
    // eslint-disable-next-line
    [toggle]
  );

  useEffect(() => {
    listBusinessPartnerUsers();
    // eslint-disable-next-line
  }, []);

  const handleClose = () => {
    // props.listAllBusinessPartnersForPartnerUseCaseMaster();
    // props.listAllBusinessPartnersForPartnerUserMaster();
    props.setOpenListBusinessPartnerUsers(false);
  };

  const listBusinessPartnerUsers = () => {
    setLoader(true);
    useFetch("/api/ListBusinessPartnerUsers", "GET", {
      config: {
        params: {
          businessPartnerId: props.storeRowOpenListBusinessPartnerUsers.current.original.businessPartnerId,
          companyId: props.companyId || DEFAULT_COMPANY_ID,
          branchCode: props.branchCode || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: (response) => {
        const businessPartnerContactList = response.data.businessPartnerContactList?.map((object: any) => {
          return { ...object, isEdit: false };
        });

        storePartnerUserMasterData.current = businessPartnerContactList;
        setRowsDataPartnerUserMaster(businessPartnerContactList);
        setLoader(false);
      },
      catchCallBack: () => {
        setLoader(false);
      },
    });
  };

  const addNewPartnerUser = async (row: Row<any>) => {
    await useFetch("/api/AddBusinessPartnerUserWithEmailSetting", "POST", {
      showSuccessToast: true,
      data: {
        businessPartnerId: storePartnerNameValueFlag.current
          ? storePartnerIdValueRef.current
          : row.original.businessPartnerId,
        contact: {
          name: storePartnerUserNameValueFlag.current ? storePartnerUserNameValueRef.current : row.original.name,
          email: storeEmailValueFlag.current ? storeEmailValueRef.current : row.original.email,
          designationName: storeDesignationValueFlag.current
            ? storeDesignationValueRef.current
            : row.original.designationName,
          ledgerRequest: storeLedgerRequestValueFlag.current
            ? storeLedgerRequestValueRef.current
            : row.original.ledgerRequest,
          balanceConfirmation: storeBalanceConfirmationValueFlag.current
            ? storeBalanceConfirmationValueRef.current
            : row.original.balanceConfirmation,
          paymentAdvice: storePaymentAdviceValueFlag.current
            ? storePaymentAdviceValueRef.current
            : row.original.paymentAdvice,
          msmeConfirmation: msmeConfirmationValueFlag.current
            ? msmeConfirmationValueRef.current
            : row.original.msmeConfirmation,
        },
        companyId: props.companyId || DEFAULT_COMPANY_ID,
        branchCode: props.branchCode || DEFAULT_BRANCH_CODE,
      },
      thenCallBack: () => {
        listBusinessPartnerUsers();
        console.log(
          storePartnerUserNameValue,
          storeEmailValue,
          storeDesignationValue,
          storeBalanceConfirmationValue,
          storeLedgerRequestValue,
          storePaymentAdviceValue
        );
        const partnerUserMasterDataObj = {
          businessPartnerId: storePartnerNameValueFlag.current
            ? storePartnerIdValueRef.current
            : row.original.businessPartnerId,
          name: storePartnerUserNameValueFlag.current ? storePartnerUserNameValueRef.current : row.original.name,
          email: storeEmailValueFlag.current ? storeEmailValueRef.current : row.original.email,
          designationName: storeDesignationValueFlag.current
            ? storeDesignationValueRef.current
            : row.original.designationName,
          ledgerRequest: storeLedgerRequestValueFlag.current
            ? storeLedgerRequestValueRef.current
            : row.original.ledgerRequest,
          balanceConfirmation: storeBalanceConfirmationValueFlag.current
            ? storeBalanceConfirmationValueRef.current
            : row.original.balanceConfirmation,
          paymentAdvice: storePaymentAdviceValueFlag.current
            ? storePaymentAdviceValueRef.current
            : row.original.paymentAdvice,
          msmeConfirmation: msmeConfirmationValueFlag.current
            ? msmeConfirmationValueRef.current
            : row.original.msmeConfirmation,
          isEdit: false,
        };
        const newPartnerUserList: any = [];
        storePartnerUserMasterData.current?.forEach((ele: any, eleInd: number) => {
          if (eleInd === row.index) {
            newPartnerUserList.push(partnerUserMasterDataObj);
          } else {
            newPartnerUserList.push(ele);
          }
        });

        storePartnerUserMasterData.current = newPartnerUserList;
        setRowsDataPartnerUserMaster(newPartnerUserList);

        storePartnerUserNameValueFlag.current = false;
        storePartnerNameValueFlag.current = false;
        storeEmailValueFlag.current = false;
        storeDesignationValueFlag.current = false;
        storeBalanceConfirmationValueFlag.current = false;
        storeLedgerRequestValueFlag.current = false;
        storePaymentAdviceValueFlag.current = false;
        msmeConfirmationValueFlag.current = false;

        setDisableAddPartnerUser(false);
      },
    });
    // }
  };

  const deleteBusinessPartnerUser = async (row: Row<any>) => {
    await useFetch("/api/DeleteBusinessPartnerUser", "POST", {
      showSuccessToast: true,
      data: {
        businessPartnerId: row.original.businessPartnerId,
        businessPartnerName: row.original.businessPartnerName,
        emailId: row.original.email,
        companyId: props.companyId || DEFAULT_COMPANY_ID,
        branchCode: props.branchCode || DEFAULT_BRANCH_CODE,
      },
      thenCallBack: () => {
        listBusinessPartnerUsers();
      },
    });
  };

  const addRowPartnerUserInList = () => {
    const newObj = {
      businessPartnerId: props.storeRowOpenListBusinessPartnerUsers.current.original.businessPartnerId,
      businessPartnerName: props.storeRowOpenListBusinessPartnerUsers.current.original.businessPartnerName,
      name: "",
      email: "",
      designationName: "",
      ledgerRequest: "to",
      balanceConfirmation: "to",
      paymentAdvice: "to",
      msmeConfirmation: "to",
      isEdit: true,
    };
    const newList = [...rowsDataPartnerUserMaster, newObj];
    storePartnerUserMasterData.current = newList;
    setRowsDataPartnerUserMaster(newList);
  };

  return (
    <>
      <header className="modal-card-head">
        <p className="modal-card-title">
          {props.storeRowOpenListBusinessPartnerUsers.current.original.businessPartnerName} - users
        </p>
        <button
          className="delete"
          aria-label="close"
          onClick={() => {
            handleClose();
          }}
        />
      </header>
      <section className="modal-card-body">
        {loader ? (
          <Loader />
        ) : (
          <Grid>
            <BpDashboardTable
              columns={columnDefinitionPartnerUserList}
              rows={rowsDataPartnerUserMaster}
              sortEnable={true}
            />
          </Grid>
        )}
      </section>
      <footer className="modal-card-foot space_between">
        <div>
          {!loader && (
            <Button
              className="theme_btn"
              onClick={() => {
                addRowPartnerUserInList();
                setDisableAddPartnerUser(true);
              }}
              disabled={disableAddPartnerUser}
            >
              Add partner's user
            </Button>
          )}
        </div>
      </footer>
    </>
  );
};

export default ListBusinessPartnerUsers;

import { Clear } from "@mui/icons-material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import EditIcon from "@mui/icons-material/Edit";
import HistoryIcon from "@mui/icons-material/History";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Buffer } from "buffer";
import saveAs from "file-saver";
import { MRT_EditActionButtons } from "material-react-table";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import CustomToast from "src/Components/CustomToast";
import ReactTable, { ColDef } from "src/Components/ReactTable/ReactTable";
import { TicketDetails, TicketFollowUpType } from "src/entity/recon-entity/ReconInterfaces";
import { isOverFlowing } from "src/Utils/Common/CheckTextOverflow";
import { filterDateBetweenStartAndEndDate, formatDate, formatDateTime24Hours } from "src/Utils/DateUtils";
import { uiLoggerNamesWorkflow } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID } from "../PartnerCommunication/CommonLegacy/CommonComponents";
import { NdButton } from "../PartnerCommunication/MsmePartnerPortal/CommonComponents";
import { Recon360Context } from "../Recon360";
import { TabPanel } from "../ReconSettings/ReconSettings";
import BulkTasksModal from "./BulkTasksModal";
import { TICKET_PRIORITIES } from "./CreateTicket";
import DiscardTicketModal from "./DiscardTicketModal";
import SaveTicketModal from "./SaveTicketModal";
import TaskAttachmentsModal from "./TaskAttachmentsModal";
import "./ViewTickets.scss";
import ViewTicketsFilter from "./ViewTicketsFilter";

const filter = createFilterOptions<any>();

export enum BackendTicketStatuses {
  Saved = "Saved Ticket",
  Discarded = "Discarded Ticket",
  Cleared = "Cleared Ticket",
}
export enum UITicketStatuses {
  Save = "Save Ticket",
  Discard = "Discard Ticket",
  Clear = "Clear Ticket",
}
export enum TicketMetaStatuses {
  Saved = "Saved",
  Discarded = "Discarded",
  Cleared = "Cleared",
  InProgress = "In Progress",
}
enum TicketViewType {
  All = "All",
  Ongoing = "Ongoing",
  Previous = "Previous",
  Discarded = "Discarded",
}
export const mappingForUiLogStatuses = {
  "Both Side Ledger Pending": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_BOTH_SIDE_LEDGER_PENDING_CLICK,
  "Partner Ledger Pending": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_PARTNER_LEDGER_PENDING_CLICK,
  "Own Ledger Pending": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_OWN_LEDGER_PENDING_CLICK,
  "Issue in Ledger": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_ISSUE_IN_LEDGER_CLICK,
  "Yet to Start": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_YET_TO_START_CLICK,
  "Recon in Progress": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_RECON_IN_PROGRESS_CLICK,
  "Maker Passed": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_MAKER_PASSED_CLICK,
  "Checker Passed": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_CHECKER_PASSED_CLICK,
  "Checker Rejected": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_CHECKER_REJECTED_CLICK,
  "Sent to Approver": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_SENT_TO_APPROVER_CLICK,
  "Approver Passed": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_APPROVER_PASSED_CLICK,
  "Approver Rejected": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_APPROVER_REJECTED_CLICK,
  "Technical Issue": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_TECHNICAL_ISSUE_CLICK,
  Dropped: uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_DROPPED_CLICK,
  "Saved Ticket": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_SAVE_TICKET_CLICK,
  "Discarded Ticket": uiLoggerNamesWorkflow.UI_WF_DB_RECON_STATUS_DISCARD_TICKET_CLICK,
};

export const ViewTickets = (props) => {
  const {
    companyId,
    branchCode,
    showSaveTicketModal,
    setShowSaveTicketModal,
    showDiscardTicketModal,
    setShowDiscardTicketModal,
    currentEditableBpId,
    storeAllCategories,
    currentSelectedTaskDetails,
  } = useContext(Recon360Context);
  const { actor } = useContext(userContext);

  const [ongoingTickets, setOngoingTickets] = React.useState<TicketDetails[]>(props.allTickets.ongoingTickets || []);
  const [previousTickets, setPreviousTickets] = React.useState<TicketDetails[]>(props.allTickets?.clearedTickets || []);
  const [discardedTickets, setDiscardedTickets] = React.useState<TicketDetails[]>(
    props.allTickets?.discardedTickets || []
  );
  const [tabValue, setTabValue] = React.useState(0);
  const [isFilterApplied, setIsFilterApplied] = React.useState(false);

  const [downloadingOngoingTasksReport, setDownloadingOngoingTasksReport] = React.useState(false);
  const [downloadingClearedTasksReport, setDownloadingClearedTasksReport] = React.useState(false);
  const [downloadingDiscardedTasksReports, setDownloadingDiscardedTasksReports] = React.useState(false);

  const newMakerId = useRef(null);
  const newCheckerId = useRef(null);
  const newApproverId = useRef(null);
  const editingBpId = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [currentTaskId, setCurrentTaskId] = useState(null);
  const [isStatusUpdating, setIsStatusUpdating] = useState(false);

  const [showBulkTasksModal, setShowBulkTasksModal] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [clearRows, setClearRows] = useState(false);

  const [showTaskAttachmentsModal, setShowTaskAttachmentsModal] = useState<boolean>(false);

  useEffect(() => {
    let ongoingTicketsRaw = props.allTickets?.ongoingTickets;

    if (window.localStorage.getItem("bpIdsForWhichTasksAlreadyExists")) {
      const userData = JSON.parse(window.localStorage.getItem("bpIdsForWhichTasksAlreadyExists"));
      ongoingTicketsRaw = ongoingTicketsRaw.filter((ticket) => userData.includes(ticket.id));
      window.localStorage.removeItem("bpIdsForWhichTasksAlreadyExists");
    }

    setOngoingTickets(ongoingTicketsRaw);
    setPreviousTickets(props.allTickets?.clearedTickets);
    setDiscardedTickets(props.allTickets?.discardedTickets);
  }, [props.allTickets]);

  const downloadAccountTicketsReport = async (view: string) => {
    if (view === TicketViewType.Ongoing) setDownloadingOngoingTasksReport(true);
    else if (view === TicketViewType.Previous) setDownloadingClearedTasksReport(true);
    else if (view === TicketViewType.Discarded) setDownloadingDiscardedTasksReports(true);
    await useFetch(`/api/tickets/GetReportForAccount?view=${view}`, "GET", {
      thenCallBack: (response) => {
        const { excelBuffer, filename } = response.data;

        const excelData = Buffer.from(excelBuffer, "base64");
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const blob = new Blob([excelData], { type: fileType });

        saveAs(blob, filename);
        if (view === TicketViewType.Ongoing) setDownloadingOngoingTasksReport(false);
        else if (view === TicketViewType.Previous) setDownloadingClearedTasksReport(false);
        else if (view === TicketViewType.Discarded) setDownloadingDiscardedTasksReports(false);
        toast.success(<CustomToast message="Report downloaded successfully" />);
      },
      catchCallBack: () => {
        if (view === TicketViewType.Ongoing) setDownloadingOngoingTasksReport(false);
        else if (view === TicketViewType.Previous) setDownloadingClearedTasksReport(false);
        else if (view === TicketViewType.Discarded) setDownloadingDiscardedTasksReports(false);
        toast.error(<CustomToast message="Error in downloading report" />);
      },
    });
  };

  const updateTicketStatus = (status: string, ticketId: number) => {
    setIsStatusUpdating(true);
    uiLogger(mappingForUiLogStatuses[status].functionName, companyId.current, branchCode.current, {
      message: mappingForUiLogStatuses[status].message,
      businessPartnerId: editingBpId.current,
      ticketStatus: status,
      idOfTicket: ticketId,
    });
    useFetch("/api/tickets/UpdateTicketStatus", "POST", {
      data: {
        ticketId: ticketId,
        status: status,
      },
      thenCallBack: () => {
        props.fetchTickets();
        setIsStatusUpdating(false);
        setShowSaveTicketModal(false);
        setShowDiscardTicketModal(false);
        toast.success(<CustomToast message="Successfully changed ticket status" />);
      },
      catchCallBack: (error) => {
        const message = error?.response?.data?.message || "Error in changing ticket status";
        props.fetchTickets();
        setIsStatusUpdating(false);
        setShowSaveTicketModal(false);
        setShowDiscardTicketModal(false);
        toast.error(<CustomToast message={message} />);
      },
    });
  };

  // Updates Own and Bp Categories for each row on Blur
  const updateOwnAndBusinessPartnerMappingCategories = async (row, value: string[]) => {
    useFetch("/api/UpdateOwnAndBusinessPartnerMappingCategories", "POST", {
      data: {
        ownId: actor.id,
        businessPartnerId: row.original.businessPartnerId,
        category: value,
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
      },
      thenCallBack: () => {
        props.fetchTickets();
      },
    });
  };

  const columnDefinitionTickets = useMemo(
    (): ColDef<TicketDetails>[] => [
      {
        header: "Task ID",
        id: "id",
        sticky: "left",
        Edit: () => null,
        enableEditing: false,
        enablePinning: false,
        enableColumnDragging: false,
        enableResizing: false,
        accessorFn: (row) => row.id?.toString() || "",
        filterVariant: "autocomplete",
        Cell: ({ row }) => (
          <div style={{ textAlign: "center" }}>
            {row.original.metaStatus === TicketMetaStatuses.InProgress ? (
              <a
                onClick={() => {
                  const { businessPartnerId, businessPartner, id: ticketId, status } = row.original;
                  uiLogger(
                    uiLoggerNamesWorkflow.UI_WF_DB_PARTNER_SELECT_CLICK.functionName,
                    companyId.current,
                    branchCode.current,
                    {
                      message: uiLoggerNamesWorkflow.UI_WF_DB_PARTNER_SELECT_CLICK.message,
                      businessPartnerId: businessPartnerId,
                      idOfTicket: Number(ticketId),
                      ticketStatus: status,
                    }
                  );
                  const params = `bpId=${businessPartnerId}&bpName=${btoa(businessPartner)}`;
                  const uri = `/${actor.name}/recon360/Summary/Ledger?${params}`;
                  window.open(uri, "_blank").focus();
                }}
                className="textOverflow_hidden_anchor"
              >
                {row.original.id}
              </a>
            ) : (
              <span>{row.original.id}</span>
            )}
          </div>
        ),
        size: 135,
      },
      {
        header: "Business Partner",
        id: "Business partner name",
        sticky: "left",
        enableEditing: false,
        enablePinning: false,
        filterVariant: "autocomplete",
        Edit: () => null,
        accessorFn: (row) => row.businessPartner || "",
        Cell: ({ row }) => (
          <div
            style={{ width: "inherit" }}
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
          >
            {row.original.metaStatus === TicketMetaStatuses.InProgress ? (
              <a
                onClick={() => {
                  const { businessPartnerId, businessPartner, id: ticketId, status } = row.original;
                  uiLogger(
                    uiLoggerNamesWorkflow.UI_WF_DB_PARTNER_SELECT_CLICK.functionName,
                    companyId.current,
                    branchCode.current,
                    {
                      message: uiLoggerNamesWorkflow.UI_WF_DB_PARTNER_SELECT_CLICK.message,
                      businessPartnerId: businessPartnerId,
                      idOfTicket: Number(ticketId),
                      ticketStatus: status,
                    }
                  );
                  const params = `bpId=${businessPartnerId}&bpName=${btoa(businessPartner)}`;
                  const uri = `/${actor.name}/recon360/Summary/Ledger?${params}`;
                  window.open(uri, "_blank").focus();
                }}
                className="textOverflow_hidden_anchor"
              >
                {row.original.businessPartner}
              </a>
            ) : (
              <span>{row.original.businessPartner}</span>
            )}
          </div>
        ),
        size: 250,
        minSize: 250,
      },
      {
        header: "Vendor Code",
        accessorFn: (row) => row?.bpVendorCode || "",
        enableSorting: true,
        Edit: () => null,
        enableEditing: false,
        size: 200,
        filterVariant: "autocomplete",
        filterFn: "contains",
      },
      {
        header: "Category",
        accessorFn: (row) => row.category?.join(", ") || "",
        size: 200,
        Edit: () => null,
        enableEditing: false,
        filterVariant: "multi-select",
        Cell: ({ row }) => (
          <div className="category_ui" style={{ width: "100%" }}>
            <Autocomplete
              limitTags={1}
              value={row.original.category !== null && row.original.category?.length > 0 ? row.original.category : []}
              onChange={(_ev, val) => {
                let value = val as string[];
                if (value !== null && value?.length > 0) {
                  if (value[value.length - 1]?.includes("+Add => ")) {
                    value[value.length - 1] = value[value.length - 1].replace('+Add => "', "");
                    value[value.length - 1] = value[value.length - 1].replace('"', "");
                    const removeEmptyVal = value?.filter((el) => {
                      return el !== "";
                    });
                    value = removeEmptyVal;
                  }
                }
                storeAllCategories.current = [...new Set([...storeAllCategories.current, ...value])];
                updateOwnAndBusinessPartnerMappingCategories(row, value);
              }}
              noOptionsText={"Enter a new category"}
              multiple={true}
              id="tags-outlined"
              options={storeAllCategories?.current}
              getOptionLabel={(option) => option}
              filterSelectedOptions={true}
              filterOptions={(_, params) => {
                const filtered = filter(
                  storeAllCategories?.current === null ? [] : storeAllCategories?.current,
                  params
                );
                // Suggest the creation of a new value
                if (params.inputValue !== "") {
                  filtered.push(`+Add => "${params.inputValue.trim()}"`);
                }
                return filtered;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // placeholder="Add"
                  sx={{
                    "& input": {
                      px: "12px !important",
                    },
                  }}
                />
              )}
              sx={{
                "&, & div": {
                  borderTopLeftRadius: "12px",
                  borderTopRightRadius: "12px",
                },
                "& fieldset": {
                  borderWidth: "0.4px",
                  borderRadius: "12px",
                },
              }}
            />
          </div>
        ),
      },
      {
        header: "Status",
        id: "Status",
        filterVariant: "multi-select",
        accessorFn: (row) => row.status || "",
        Cell: ({ row, column }) => {
          const onChangeHandler = (_, newValue) => {
            if (newValue === BackendTicketStatuses.Saved) {
              setCurrentTaskId(row.original.id);
              setShowSaveTicketModal(true);
            } else if (newValue === BackendTicketStatuses.Discarded) {
              setCurrentTaskId(row.original.id);
              setShowDiscardTicketModal(true);
            } else {
              row.original.status = newValue;
              updateTicketStatus(newValue, row.original.id);
            }
          };
          return (
            <Autocomplete
              disabled={
                column.getIsGrouped() ||
                row.original.metaStatus !== TicketMetaStatuses.InProgress ||
                selectedRow.length > 0
              }
              value={row.original.status}
              onFocus={() => {
                currentEditableBpId.current = row.original.businessPartnerId.toString();
                editingBpId.current = row.original.businessPartnerId;
              }}
              onChange={onChangeHandler}
              disableClearable
              options={props.allStatuses}
              renderOption={(props, status) => {
                let statusText = status;
                if (status === BackendTicketStatuses.Cleared) return null;
                if (status === BackendTicketStatuses.Saved) statusText = UITicketStatuses.Save;
                if (status === BackendTicketStatuses.Discarded) statusText = UITicketStatuses.Discard;
                return (
                  <li {...props} key={status}>
                    <ListItemText
                      sx={{
                        color: (status === "Saved Ticket" && "#27b27c") || (status === "Discarded Ticket" && "#d32f2f"),
                      }}
                    >
                      {statusText}
                    </ListItemText>
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  sx={{
                    "& .MuiInput-underline:before": {
                      borderBottom: "none",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottom: "none",
                    },
                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                      borderBottom: "none",
                    },
                    "& .MuiInput-underline.Mui-disabled:before": {
                      borderBottom: "none",
                    },
                  }}
                  {...params}
                  variant="standard"
                />
              )}
            />
          );
        },
        size: 230,
        minSize: 230,
        Edit: () => null,
      },
      {
        header: "Maker",
        id: "Maker",
        filterVariant: "multi-select",
        filterFn: (row, _, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.maker);
        },
        accessorFn: (row) => row.maker || "",
        Cell: ({ row }) => {
          return (
            <div
              className="textOverflow_hidden"
              ref={(th) => {
                if (th && isOverFlowing(th)) th.title = th.innerText;
              }}
              style={{ width: "inherit" }}
            >
              {row.original.maker ? row.original.maker : "-"}
            </div>
          );
        },
        Edit: ({ column, row }) => {
          const onChangeHandler = (_, newValue) => {
            row._valuesCache[column.id] = newValue.name;
            newMakerId.current = newValue.id;
          };

          const defaultMaker = props.allUsersWithIds.filter((user) => user.id === row.original.makerId) || [];
          if (defaultMaker.length > 0) {
            newMakerId.current = defaultMaker[0].id;
          }
          return (
            <Autocomplete
              defaultValue={defaultMaker.length > 0 ? defaultMaker[0] : null}
              onChange={onChangeHandler}
              disableClearable
              options={props.allUsersWithIds}
              renderOption={(props, item) => (
                <li {...props} key={item.id}>
                  <ListItemText>{item.name}</ListItemText>
                </li>
              )}
              getOptionLabel={(option: { name: string; id: number }) => option.name}
              renderInput={(params) => <TextField {...params} label="Maker" variant="standard" />}
            />
          );
        },
        size: 150,
        minSize: 150,
      },
      {
        header: "Checker",
        id: "Checker",
        filterVariant: "multi-select",
        filterFn: (row, _, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.checker);
        },
        accessorFn: (row) => row.checker || "",
        Cell: ({ row }) => (
          <div
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
            style={{ width: "inherit" }}
          >
            {row.original.checker ? row.original.checker : "-"}
          </div>
        ),
        Edit: ({ column, row }) => {
          const onChangeHandler = (_, newValue) => {
            newCheckerId.current = newValue.id;
            row._valuesCache[column.id] = newValue.name;
          };
          const defaultChecker = props.allUsersWithIds.filter((user) => user.id === row.original.checkerId) || [];
          if (defaultChecker.length > 0) {
            newCheckerId.current = defaultChecker[0].id;
          }
          return (
            <Autocomplete
              onChange={onChangeHandler}
              defaultValue={defaultChecker.length > 0 ? defaultChecker[0] : null}
              disableClearable
              getOptionLabel={(option: { name: string; id: number }) => option.name}
              options={props.allUsersWithIds}
              renderOption={(props, item) => (
                <li {...props} key={item.id}>
                  <ListItemText>{item.name}</ListItemText>
                </li>
              )}
              renderInput={(params) => <TextField {...params} label="Checker" variant="standard" />}
            />
          );
        },
        size: 160,
        minSize: 160,
      },
      {
        header: "Approver",
        id: "Approver",
        filterVariant: "multi-select",
        filterFn: (row, _, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.approver ? row.original.approver : "-");
        },
        accessorFn: (row) => row.approver || "-",
        Cell: ({ row }) => (
          <div
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
            style={{ width: "inherit" }}
          >
            {row.original.approver ? row.original.approver : "-"}
          </div>
        ),
        Edit: ({ column, row }) => {
          const onChangeHandler = (_, newValue) => {
            row._valuesCache[column.id] = newValue.name;
            newApproverId.current = newValue.id;
          };

          const defaultApprover = props.allUsersWithIds.filter((user) => user.id === row.original.approverId) || [];
          if (defaultApprover.length > 0) {
            newApproverId.current = defaultApprover[0].id;
          }
          return (
            <Autocomplete
              onChange={onChangeHandler}
              disableClearable
              defaultValue={defaultApprover.length > 0 ? defaultApprover[0] : null}
              getOptionLabel={(option: { name: string; id: number }) => option.name}
              options={props.allUsersWithIds}
              renderOption={(props, item) => (
                <li {...props} key={item.id}>
                  <ListItemText>{item.name}</ListItemText>
                </li>
              )}
              renderInput={(params) => <TextField {...params} label="Approver" variant="standard" />}
            />
          );
        },
        size: 170,
        minSize: 170,
      },
      {
        header: "Last User",
        id: "Last User",
        filterVariant: "multi-select",
        enableEditing: false,
        Edit: () => null,
        accessorFn: (row) => row.lastActiveUser || "-",
        filterFn: (row, _, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.lastActiveUser ? row.original.lastActiveUser : "-");
        },
        Cell: ({ row }) => (
          <div
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
            style={{ width: "inherit" }}
          >
            {row.original.lastActiveUser ? row.original.lastActiveUser : "-"}
          </div>
        ),
        size: 180,
        minSize: 180,
      },
      {
        header: "Priority",
        id: "Priority",
        filterVariant: "multi-select",
        accessorFn: (row) => row.priority || "",
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">{row.original.priority ? row.original.priority : "-"}</div>
        ),
        size: 152,
        minSize: 152,
        Edit: ({ column, row }) => {
          const onChangeHanlder = (_, newValue) => {
            row._valuesCache[column.id] = newValue;
          };
          return (
            <Autocomplete
              onChange={onChangeHanlder}
              disableClearable
              defaultValue={row.original.priority}
              options={TICKET_PRIORITIES}
              renderInput={(params) => <TextField {...params} label="Priority" variant="standard" />}
            />
          );
        },
      },

      {
        header: "Bucket",
        id: "Bucket",
        accessorFn: (row) => row.metaStatus || "",
        enableEditing: false,
        Edit: () => null,
        filterVariant: "multi-select",
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">{row.original.metaStatus ? row.original.metaStatus : "-"}</div>
        ),
        size: 150,
        minSize: 150,
      },
      {
        header: "Due Date",
        id: "Due Date",
        enableEditing: false,
        filterVariant: "date-range",
        filterFn: (row, _, filterValue) => {
          const startDate = filterValue[0] && filterValue[0].$d;
          const endDate = filterValue[1] && filterValue[1].$d;
          return filterDateBetweenStartAndEndDate(startDate, endDate, row.original.dueDate);
        },
        accessorFn: (row) => new Date(row.dueDate),
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">{row.original.dueDate ? formatDate(row.original.dueDate) : "-"}</div>
        ),
        size: 210,
        minSize: 210,
        Edit: ({ column, row }) => {
          const [selectedDate, setSelectedDate] = useState<Date | null>(
            row.original.dueDate ? new Date(row.original.dueDate) : null
          );
          const [errorText, setErrorText] = useState("");
          const dueDateChangeHandler = (date: Date) => {
            setSelectedDate(date);
            row._valuesCache[column.id] = date;
          };
          return (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Due Date"
                value={selectedDate}
                onError={(error) => {
                  if (error) setErrorText(error);
                  else setErrorText("");
                }}
                onChange={dueDateChangeHandler}
                format="dd/MM/yyyy"
                slotProps={{
                  textField: {
                    helperText: errorText && "Invalid Date",
                    variant: "standard",
                    InputProps: {
                      startAdornment: (
                        <InputAdornment position="start" sx={{ margin: 0 }}>
                          {selectedDate && (
                            <IconButton
                              size="small"
                              sx={{ position: "absolute", right: "30px" }}
                              onClick={() => {
                                setSelectedDate(null);
                                row._valuesCache[column.id] = null;
                              }}
                            >
                              <Clear />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    },
                  },
                }}
              />
            </LocalizationProvider>
          );
        },
      },
      {
        header: "Creation Date",
        id: "Creation Date",
        enableEditing: false,
        filterVariant: "date-range",
        filterFn: (row, _, filterValue) => {
          const startDate = filterValue[0] && filterValue[0].$d;
          const endDate = filterValue[1] && filterValue[1].$d;
          return filterDateBetweenStartAndEndDate(startDate, endDate, row.original.createdAt);
        },
        Edit: () => null,
        accessorFn: (row) => new Date(row.createdAt),
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">{row.original.createdAt ? formatDate(row.original.createdAt) : "-"}</div>
        ),
        size: 210,
        minSize: 210,
      },
      {
        header: "Time of Last Status Change",
        id: "Time of Last Status Change",
        enableEditing: false,
        Edit: () => null,
        filterVariant: "date-range",
        filterFn: (row, _, filterValue) => {
          const startDate = filterValue[0] && filterValue[0].$d;
          const endDate = filterValue[1] && filterValue[1].$d;
          return filterDateBetweenStartAndEndDate(startDate, endDate, row.original.lastStatusChangeTime);
        },
        accessorFn: (row) => new Date(row.lastStatusChangeTime),
        // Render lastStatusChangeTime in the format "YYYY-MM-DD  HH:MM AM/PM"
        // AM/PM is compulsory
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.lastStatusChangeTime ? formatDateTime24Hours(row.original.lastStatusChangeTime) : "-"}
          </div>
        ),
        size: 320,
        minSize: 320,
      },
      {
        header: "Remarks",
        id: "remarks",
        accessorFn: (row) => row.remarks || "",
        filterFn: "contains",
        Cell: ({ row }) => (
          <div
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
            style={{ width: "inherit" }}
          >
            {row.original.remarks ? row.original.remarks : "-"}
          </div>
        ),
        size: 250,
        minSize: 250,
        Edit: ({ column, row }) => {
          const onChangeHandler = (event) => {
            row._valuesCache[column.id] = event.target.value;
          };
          return (
            <TextField
              label="Remarks"
              onChange={onChangeHandler}
              defaultValue={row.original.remarks}
              multiline
              maxRows={4}
              variant="standard"
            />
          );
        },
      },
      {
        header: "Mail Follow Ups",
        id: "mailfollowups",
        Edit: () => null,
        enableEditing: false,
        filterVariant: "range",
        filterFn: "betweenInclusive",
        accessorFn: (row) => row.followUpCounts?.[TicketFollowUpType?.Mail] || 0,
        Cell: ({ row }) => (
          <Stack direction="row" columnGap={1} justifyContent="center">
            <span className="fs_16 fw_600">
              {(row.original.followUpCounts?.[TicketFollowUpType?.Mail] || "0") + " Times"}
            </span>
            <span>
              {row.original.followUpCounts?.[TicketFollowUpType?.Mail] !== 0 &&
                formatDate(row.original.followUpLastTime?.[TicketFollowUpType?.Mail])}
            </span>
          </Stack>
        ),
        size: 220,
        minSize: 220,
      },
      {
        header: "Conversation Follow Ups",
        id: "conversationfollowups",
        enableEditing: false,
        filterVariant: "range",
        filterFn: "betweenInclusive",
        Edit: () => null,
        accessorFn: (row) => row.followUpCounts?.[TicketFollowUpType?.Conversation] || 0,
        Cell: ({ row }) => (
          <Stack direction="row" columnGap={1} justifyContent="center">
            <span className="fs_16 fw_600">
              {(row.original.followUpCounts?.[TicketFollowUpType?.Conversation] || "0") + " Times"}
            </span>
            <span>
              {row.original.followUpCounts?.[TicketFollowUpType?.Conversation] !== 0 &&
                formatDate(row.original.followUpLastTime?.[TicketFollowUpType?.Conversation])}
            </span>
          </Stack>
        ),
        size: 300,
        minSize: 300,
      },
    ],

    [props.allStatuses, props.allUsersWithIds, ongoingTickets, previousTickets, discardedTickets, selectedRow]
  );
  const handleSaveUser = ({ values, table, row }) => {
    const payloadObj = { ticketId: row.original.id };
    if (newMakerId.current && newMakerId.current !== row.original.makerId) {
      Object.assign(payloadObj, { makerId: newMakerId.current });
    }
    if (newCheckerId.current && newCheckerId.current !== row.original.checkerId) {
      Object.assign(payloadObj, { checkerId: newCheckerId.current });
    }
    if (newApproverId.current && newApproverId.current !== row.original.approverId) {
      Object.assign(payloadObj, { approverId: newApproverId.current });
    }
    if (values.Priority !== row.original.priority) {
      Object.assign(payloadObj, { priority: values.Priority });
    }
    if (values["Due Date"]?.getTime() !== new Date(row.original.dueDate)?.getTime()) {
      if (values["Due Date"] !== null || row.original.dueDate !== null)
        Object.assign(payloadObj, { dueDate: values["Due Date"] });
    }

    if (values.remarks !== row.original.remarks) {
      if (values.remarks !== "" || row.original.remarks !== null)
        Object.assign(payloadObj, { remarks: values.remarks });
    }
    setIsEditing(true);
    uiLogger(
      uiLoggerNamesWorkflow.UI_WF_DB_ACTIONS_EDIT_SAVE_CLICK.functionName,
      companyId.current,
      branchCode.current,
      {
        message: uiLoggerNamesWorkflow.UI_WF_DB_ACTIONS_EDIT_SAVE_CLICK.message,
        idOfTicket: row.original.id,
      }
    );
    useFetch("/api/tickets/UpdateTicket", "POST", {
      data: payloadObj,
      thenCallBack: () => {
        setIsEditing(false);
        props.fetchTickets();
        table.setEditingRow(null);
        toast.success(<CustomToast message="Task updated successfully" />);
      },
      catchCallBack: () => {
        setIsEditing(false);
        props.fetchTickets();
        table.setEditingRow(null);
        toast.success(<CustomToast message="Error in updating the task" />);
      },
    });
  };
  return (
    <div className="view-tickets">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabValue}
          onChange={(e, val) => {
            e.preventDefault();
            setTabValue(val);
          }}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="ONGOING TASKS" sx={{ textTransform: "none" }} />
          <Tab label="PREVIOUS TASKS" sx={{ textTransform: "none" }} />
          <Tab label="DISCARDED TASKS" sx={{ textTransform: "none" }} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <ViewTicketsFilter
          rowsDataTicket={ongoingTickets}
          setRowsDataTicket={setOngoingTickets}
          storeRowsDataTicket={props.allTickets?.ongoingTickets || []}
          openFilter={isFilterApplied}
          setOpenFilter={setIsFilterApplied}
          allStatuses={props.allStatuses || []}
          allUsers={props.allUsers || []}
          allPartners={ongoingTickets.map((ticket) => ticket.businessPartner)}
        />
        <Box sx={{ backgroundColor: "#fff", borderRadius: "20px", boxShadow: 5 }}>
          <Stack direction="row" p={2}>
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              Ongoing Tasks
            </Typography>
            <Button
              onClick={() => setShowBulkTasksModal(true)}
              variant="outlined"
              sx={{
                fontSize: "15px",
                fontWeight: "500",
                ml: "auto",
                padding: "8px 22px",
                borderRadius: "4px !important",
                color: "rgba(84, 28, 76, 1)",
                borderColor: "rgba(84, 28, 76, 0.50)",
                "&:hover, &.Mui-focusVisible": { borderColor: "rgba(84, 28, 76, 1)" },
              }}
            >
              BULK TASKS
            </Button>
            <NdButton
              variant="contained"
              sx={{ ml: 2 }}
              onClick={() => {
                uiLogger(
                  uiLoggerNamesWorkflow.UI_WF_DB_DOWNLOAD_TASK_REPORT_CLICK.functionName,
                  companyId.current,
                  branchCode.current,
                  { message: uiLoggerNamesWorkflow.UI_WF_DB_DOWNLOAD_TASK_REPORT_CLICK.message }
                );
                downloadAccountTicketsReport(TicketViewType.Ongoing);
              }}
              disabled={downloadingOngoingTasksReport}
            >
              <LoadingIcon loading={downloadingOngoingTasksReport} />
              Download Report
            </NdButton>
            <NdButton
              variant="contained"
              sx={{ ml: 2 }}
              onClick={() => {
                uiLogger(
                  uiLoggerNamesWorkflow.UI_WF_DB_FILTER_CLICK.functionName,
                  companyId.current,
                  branchCode.current,
                  {
                    message: uiLoggerNamesWorkflow.UI_WF_DB_FILTER_CLICK.message,
                  }
                );
                setIsFilterApplied(true);
              }}
            >
              Filter
            </NdButton>
          </Stack>
          <ReactTable
            enableFacetedValues={true}
            enableRowVirtualization={false}
            layoutMode="semantic"
            columns={columnDefinitionTickets}
            rows={ongoingTickets}
            enableAggregations={true}
            setSelectedRow={setSelectedRow}
            clearRowsSelection={clearRows}
            setClearRowsSelection={setClearRows}
            renderRowActions={({ row, table }) => {
              const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
              const open = Boolean(anchorEl);
              const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
                setAnchorEl(event.currentTarget);
              };
              const handleClose = () => {
                setAnchorEl(null);
              };

              const filterSelectedFlatRows = table.getSelectedRowModel().flatRows.map((item) => item.original);

              return (
                <Box sx={{ display: "flex", gap: "1rem", justifyContent: "center" }}>
                  <IconButton onClick={(e) => handleClick(e)} disabled={filterSelectedFlatRows.length > 1}>
                    <MoreHorizIcon />
                  </IconButton>

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      disabled={row.original.metaStatus === TicketMetaStatuses.Saved}
                      onClick={() => {
                        table.setEditingRow(row);
                        handleClose();
                        uiLogger(
                          uiLoggerNamesWorkflow.UI_WF_DB_ACTIONS_EDIT_CLICK.functionName,
                          companyId.current,
                          branchCode.current,
                          {
                            message: uiLoggerNamesWorkflow.UI_WF_DB_ACTIONS_EDIT_CLICK.message,
                            idOfTicket: row.original.id,
                          }
                        );
                      }}
                    >
                      <ListItemIcon>
                        <EditIcon fontSize="small" />
                      </ListItemIcon>
                      Edit Task
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        currentSelectedTaskDetails.current = {
                          businessPartnerName: row.original.businessPartner,
                          taskId: row.original.id,
                        };
                        setShowTaskAttachmentsModal(true);
                        handleClose();
                      }}
                    >
                      <ListItemIcon>
                        <AttachFileIcon />
                      </ListItemIcon>
                      View Attachments
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        currentSelectedTaskDetails.current = {
                          businessPartnerName: row.original.businessPartner,
                          taskId: row.original.id,
                        };
                        props.setShowTasksHistoryModal(true);
                        handleClose();
                      }}
                    >
                      <ListItemIcon>
                        <HistoryIcon />
                      </ListItemIcon>
                      View History
                    </MenuItem>
                  </Menu>
                </Box>
              );
            }}
            enableEditing={true}
            editDisplayMode="modal"
            onEditingRowSave={handleSaveUser}
            isSaving={isEditing}
            renderEditRowDialogContent={({ table, row, internalEditComponents }) => {
              return (
                <>
                  <Box className="dialog_header space_between" alignItems="center" pr={1}>
                    <DialogTitle
                      sx={{ width: "430px" }}
                      ref={(th) => {
                        if (th && isOverFlowing(th)) th.title = th.innerText;
                      }}
                      className="textOverflow_hidden"
                    >
                      Edit Task for {`${row.original.businessPartner}`}
                    </DialogTitle>
                  </Box>
                  <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
                    {internalEditComponents} {/* or render custom edit components here */}
                  </DialogContent>
                  <DialogActions className="dialog_footer">
                    <MRT_EditActionButtons variant="text" table={table} row={row} />
                  </DialogActions>
                </>
              );
            }}
            internalActionsOrder={["globalFilterToggle", "filterToggle", "showHideColumns", "fullScreenToggle"]}
          />
        </Box>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <ViewTicketsFilter
          rowsDataTicket={previousTickets}
          setRowsDataTicket={setPreviousTickets}
          storeRowsDataTicket={props.allTickets?.clearedTickets || []}
          openFilter={isFilterApplied}
          setOpenFilter={setIsFilterApplied}
          allStatuses={props.allStatuses || []}
          allUsers={props.allUsers || []}
          allPartners={previousTickets.map((ticket) => ticket.businessPartner)}
        />
        <Box sx={{ backgroundColor: "#fff", borderRadius: "20px", boxShadow: 5 }}>
          <Stack direction="row" p={2}>
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              Previous Tasks
            </Typography>
            <NdButton
              variant="contained"
              sx={{ ml: "auto" }}
              onClick={() => {
                uiLogger(
                  uiLoggerNamesWorkflow.UI_WF_DB_DOWNLOAD_TASK_REPORT_CLICK.functionName,
                  companyId.current,
                  branchCode.current,
                  {
                    message: uiLoggerNamesWorkflow.UI_WF_DB_DOWNLOAD_TASK_REPORT_CLICK.message,
                  }
                );
                downloadAccountTicketsReport(TicketViewType.Previous);
              }}
              disabled={downloadingClearedTasksReport}
            >
              <LoadingIcon loading={downloadingClearedTasksReport} />
              Download Report
            </NdButton>
            <NdButton
              variant="contained"
              sx={{ ml: 2 }}
              onClick={() => {
                uiLogger(
                  uiLoggerNamesWorkflow.UI_WF_DB_FILTER_CLICK.functionName,
                  companyId.current,
                  branchCode.current,
                  {
                    message: uiLoggerNamesWorkflow.UI_WF_DB_FILTER_CLICK.functionName,
                  }
                );
                setIsFilterApplied(true);
              }}
            >
              Filter
            </NdButton>
          </Stack>

          <ReactTable
            columns={columnDefinitionTickets}
            rows={previousTickets}
            enableRowSelection={false}
            enableAggregations={true}
            enableRowVirtualization={false}
            layoutMode="semantic"
            enableFacetedValues={true}
            internalActionsOrder={["globalFilterToggle", "filterToggle", "showHideColumns", "fullScreenToggle"]}
            enableEditing={true}
            renderRowActions={({ row }) => {
              const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
              const open = Boolean(anchorEl);
              const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
                setAnchorEl(event.currentTarget);
              };
              const handleClose = () => {
                setAnchorEl(null);
              };

              return (
                <Box sx={{ display: "flex", gap: "1rem", justifyContent: "center" }}>
                  <IconButton onClick={(e) => handleClick(e)}>
                    <MoreHorizIcon />
                  </IconButton>

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        currentSelectedTaskDetails.current = {
                          businessPartnerName: row.original.businessPartner,
                          taskId: row.original.id,
                        };
                        setShowTaskAttachmentsModal(true);
                        handleClose();
                      }}
                    >
                      <ListItemIcon>
                        <AttachFileIcon />
                      </ListItemIcon>
                      View Attachments
                    </MenuItem>
                  </Menu>
                </Box>
              );
            }}
          />
        </Box>
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <ViewTicketsFilter
          rowsDataTicket={discardedTickets}
          setRowsDataTicket={setDiscardedTickets}
          storeRowsDataTicket={props.allTickets?.discardedTickets || []}
          openFilter={isFilterApplied}
          setOpenFilter={setIsFilterApplied}
          allStatuses={props.allStatuses || []}
          allUsers={props.allUsers || []}
          allPartners={discardedTickets.map((ticket) => ticket.businessPartner)}
        />
        <Box sx={{ backgroundColor: "#fff", borderRadius: "20px", boxShadow: 5 }}>
          <Stack direction="row" p={2}>
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              Discarded Tasks
            </Typography>
            <NdButton
              variant="contained"
              sx={{ ml: "auto" }}
              onClick={() => {
                uiLogger(
                  uiLoggerNamesWorkflow.UI_WF_DB_DOWNLOAD_TASK_REPORT_CLICK.functionName,
                  companyId.current,
                  branchCode.current,
                  { message: uiLoggerNamesWorkflow.UI_WF_DB_DOWNLOAD_TASK_REPORT_CLICK.message }
                );
                downloadAccountTicketsReport(TicketViewType.Discarded);
              }}
              disabled={downloadingDiscardedTasksReports}
            >
              <LoadingIcon loading={downloadingDiscardedTasksReports} />
              Download Report
            </NdButton>
            <NdButton
              variant="contained"
              sx={{ ml: 2 }}
              onClick={() => {
                uiLogger(
                  uiLoggerNamesWorkflow.UI_WF_DB_FILTER_CLICK.functionName,
                  companyId.current,
                  branchCode.current,
                  { message: uiLoggerNamesWorkflow.UI_WF_DB_FILTER_CLICK.message }
                );
                setIsFilterApplied(true);
              }}
            >
              Filter
            </NdButton>
          </Stack>

          <ReactTable
            columns={columnDefinitionTickets}
            rows={discardedTickets}
            enableRowSelection={false}
            enableAggregations={true}
            enableRowVirtualization={false}
            layoutMode="semantic"
            internalActionsOrder={["globalFilterToggle", "filterToggle", "showHideColumns", "fullScreenToggle"]}
            enableFacetedValues={true}
          />
        </Box>
      </TabPanel>
      {showSaveTicketModal && (
        <SaveTicketModal
          showSaveTicketModal={showSaveTicketModal}
          setShowSaveTicketModal={setShowSaveTicketModal}
          changeStatus={updateTicketStatus}
          ticketId={currentTaskId}
          isStatusUpdating={isStatusUpdating}
        />
      )}
      {showDiscardTicketModal && (
        <DiscardTicketModal
          showDiscardTicketModal={showDiscardTicketModal}
          setShowDiscardTicketModal={setShowDiscardTicketModal}
          changeStatus={updateTicketStatus}
          ticketId={currentTaskId}
          isStatusUpdating={isStatusUpdating}
        />
      )}
      <BulkTasksModal
        showBulkTasksModal={showBulkTasksModal}
        setShowBulkTasksModal={setShowBulkTasksModal}
        selectedTasks={selectedRow}
        allUsers={props.allUsersWithIds}
        allStatuses={props.allStatuses}
        fetchTickets={props.fetchTickets}
        setClearRows={setClearRows}
      />
      {showTaskAttachmentsModal && (
        <TaskAttachmentsModal
          open={showTaskAttachmentsModal}
          setOpen={setShowTaskAttachmentsModal}
          businessPartnerName={currentSelectedTaskDetails.current.businessPartnerName}
          ticketId={currentSelectedTaskDetails.current.taskId}
          companyId={companyId.current}
          branchCode={branchCode.current}
        />
      )}
    </div>
  );
};

import { Autocomplete, Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import MonetaryInput from "src/Components/Common/MonetaryInput";
import {
  BusinessPartnerListForBcBeta,
  BusinessPartnersListForBcBetaHistory,
} from "src/entity/recon-entity/ReconInterfaces";
import { BalanceConfirmationBetaStatuses } from "src/Utils/PartnerCommunication";
import { TagsInputUniqueKeys } from "../../ReconMIS/ClientReporting/AutoCompletes";
import { CheckboxGroup } from "../../ReconMIS/utils";
import "../Styles/LedgerRequestFilter.scss";

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

type setAppliedFilters = StateDispatch<{
  [key: string]: string;
}>;

interface BalanceConfirmationFilterProps {
  allCategories: string[];
  storeRowsDataBalanceConfirmation: BusinessPartnerListForBcBeta[];
  rowsDataBalanceConfirmation: BusinessPartnerListForBcBeta[];
  setRowsDataBalanceConfirmation: StateDispatch<BusinessPartnerListForBcBeta[]>;
  setAppliedFilters: setAppliedFilters;
  setIsFilterAppliedBalanceConfirmation: StateDispatch<boolean>;
  openBalanceConfirmationFilter: boolean;
  setOpenBalanceConfirmationFilter: StateDispatch<boolean>;
  reFilter: boolean;
  setReFilter: StateDispatch<boolean>;
  historyConsumer?: boolean;
}

export function NumberFilter<T>(minVal: number, maxVal: number, rowData: T[], key: keyof T) {
  if (minVal !== null && maxVal !== null) {
    rowData = rowData?.filter((item) => {
      if (item[key]) {
        return Number(item[key]) <= Number(maxVal) && Number(item[key]) >= Number(minVal);
      }
      return false;
    });
  }
  if (minVal !== null && maxVal === null) {
    rowData = rowData?.filter((item) => {
      if (item[key]) {
        return Number(item[key]) >= Number(minVal);
      }
      return false;
    });
  }
  if (minVal === null && maxVal !== null) {
    rowData = rowData?.filter((item) => {
      if (item[key]) {
        return Number(item[key]) <= Number(maxVal);
      }
      return false;
    });
  }

  return rowData;
}

export function DateFilter<T>(minDate: Date | string, maxDate: Date | string, rowsData: T[], key: keyof T) {
  if (minDate && maxDate) {
    rowsData = rowsData?.filter((item) => {
      if (item[key]) {
        return moment(item[key])?.format("YYYY-MM-DD") <= maxDate && moment(item[key])?.format("YYYY-MM-DD") >= minDate;
      }
      return false;
    });
  }
  if (minDate && !maxDate) {
    rowsData = rowsData?.filter((item) => {
      if (item[key]) {
        return moment(item[key])?.format("YYYY-MM-DD") >= minDate;
      }
      return false;
    });
  }
  if (!minDate && maxDate) {
    rowsData = rowsData?.filter((item) => {
      if (item[key]) {
        return moment(item[key])?.format("YYYY-MM-DD") <= maxDate;
      }
      return false;
    });
  }

  return rowsData;
}

const BalanceConfirmationBetaFilter = (props: BalanceConfirmationFilterProps) => {
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);

  const [minClosingBalance, setMinClosingBalance] = useState<number>(null);
  const [maxClosingBalance, setMaxClosingBalance] = useState<number>(null);
  const [minClosingBalanceBp, setMinClosingBalanceBp] = useState<number>(null);
  const [maxClosingBalanceBp, setMaxClosingBalanceBp] = useState<number>(null);
  const [minClosingBalanceDiff, setMinClosingBalanceDiff] = useState<number>(null);
  const [maxClosingBalanceDiff, setMaxClosingBalanceDiff] = useState<number>(null);

  const [fromDateOfMailInitiation, setFromDateOfMailInitiation] = useState<string>(null);
  const [toDateOfMailInitiation, setToDateOfMailInitiation] = useState<string>(null);
  const [fromDateOfClosingBalance, setFromDateOfClosingBalance] = useState<string>(null);
  const [toDateOfClosingBalance, setToDateOfClosingBalance] = useState<string>(null);
  const [fromDateAsOfLastBalanceConfirmed, setFromDateAsOfLastBalanceConfirmed] = useState<string>(null);
  const [toDateAsOfLastBalanceConfirmed, setToDateAsOfLastBalanceConfirmed] = useState<string>(null);
  const [partnerNames, setPartnerNames] = useState<string[]>([]);
  const [downloadAvailable, setDownloadAvailable] = useState({
    yes: false,
    no: false,
  });
  const [downloadAvailableLedger, setDownloadAvailableLedger] = useState({
    yes: false,
    no: false,
  });

  const BalanceConfirmationStatus = Array.from(new Set(Object.values(BalanceConfirmationBetaStatuses))); // only unique values

  type keys = "partnerNames" | "category" | "status";

  const [filterObj, setFilterObj] = useState<{ [k in keys]: any }>(null);

  const handleClose = () => {
    props.setOpenBalanceConfirmationFilter(false);
  };

  const filterBalanceConfirmation = () => {
    let tempRowData = props.storeRowsDataBalanceConfirmation;

    if (partnerNames?.length) {
      tempRowData = tempRowData?.filter((partnerRow) => {
        let found = false;
        partnerNames.forEach((name, _ind) => {
          if (_ind === 0) found = false;
          const partnerNameWithoutMultiSpace = name.replace(/\s+/g, " ");
          const partnerNameStr = partnerNameWithoutMultiSpace.toLowerCase();
          props.setIsFilterAppliedBalanceConfirmation(true);
          if (partnerRow.businessPartnerName?.replace(/\s+/g, " ").toLowerCase()?.includes(partnerNameStr?.trim())) {
            found = true;
          }
        });
        return found;
      });
    }

    if (selectedCategory.length > 0) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = tempRowData?.filter((item) => {
        return selectedCategory?.every((val) => item.category?.includes(val));
      });
    }
    if (selectedStatus.length > 0) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = tempRowData?.filter((item) => {
        return selectedStatus?.some((val) => item.status?.toLowerCase()?.includes(val?.toLowerCase()));
      });
    }

    if (downloadAvailable.yes || downloadAvailable.no) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = tempRowData?.filter((item) => {
        if (downloadAvailable.yes && !downloadAvailable.no) return item.signedPdf;
        if (downloadAvailable.no && !downloadAvailable.yes) return !item.signedPdf;
        else if (downloadAvailable.no && downloadAvailable.yes) return true;
        else return false;
      });
    }

    if (downloadAvailableLedger.yes || downloadAvailableLedger.no) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = tempRowData?.filter((item) => {
        if (downloadAvailableLedger.yes && !downloadAvailableLedger.no) return item.signedPdf;
        if (downloadAvailableLedger.no && !downloadAvailableLedger.yes) return !item.signedPdf;
        else if (downloadAvailableLedger.no && downloadAvailableLedger.yes) return true;
        else return false;
      });
    }

    if (minClosingBalance !== null || maxClosingBalance !== null) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = NumberFilter<BusinessPartnerListForBcBeta>(
        minClosingBalance,
        maxClosingBalance,
        tempRowData,
        "ownClosingBalance"
      );
    }

    if (minClosingBalanceBp !== null || maxClosingBalanceBp !== null) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = NumberFilter<BusinessPartnerListForBcBeta>(
        minClosingBalanceBp,
        maxClosingBalanceBp,
        tempRowData,
        "businessPartnerClosingBalance"
      );
    }

    if (minClosingBalanceDiff !== null || maxClosingBalanceDiff !== null) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = NumberFilter<BusinessPartnerListForBcBeta>(
        minClosingBalanceDiff,
        maxClosingBalanceDiff,
        tempRowData,
        "closingBalanceDifference"
      );
    }

    if (props.historyConsumer && (fromDateOfMailInitiation || toDateOfMailInitiation)) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = DateFilter<BusinessPartnersListForBcBetaHistory>(
        fromDateOfMailInitiation,
        toDateOfMailInitiation,
        tempRowData,
        "emailInitiationDate"
      );
    }

    if (fromDateOfClosingBalance || toDateOfClosingBalance) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = DateFilter<BusinessPartnerListForBcBeta>(
        fromDateOfClosingBalance,
        toDateOfClosingBalance,
        tempRowData,
        "closingBalanceDate"
      );
    }

    setFilterObj({ partnerNames: partnerNames, category: selectedCategory, status: selectedStatus });

    props.setAppliedFilters({
      "Partner Names": partnerNames?.map((name) => name?.trim()).join(", "),
      "Selected Category": selectedCategory.join(","),
      "Selected Status": selectedStatus.join(", "),
      "Closing Balance Own":
        minClosingBalance !== null && maxClosingBalance !== null
          ? `${minClosingBalance.toLocaleString("en-IN")} - ${maxClosingBalance.toLocaleString("en-IN")}`
          : minClosingBalance !== null
          ? `Minimum: ${minClosingBalance.toLocaleString("en-IN")}`
          : maxClosingBalance !== null
          ? `Maximum: ${maxClosingBalance.toLocaleString("en-IN")}`
          : "",
      "Closing Balance Partner":
        minClosingBalanceBp !== null && maxClosingBalanceBp !== null
          ? `${minClosingBalanceBp.toLocaleString("en-IN")} - ${maxClosingBalanceBp.toLocaleString("en-IN")}`
          : minClosingBalanceBp !== null
          ? `Minimum: ${minClosingBalanceBp.toLocaleString("en-IN")}`
          : maxClosingBalanceBp !== null
          ? `Maximum: ${maxClosingBalanceBp.toLocaleString("en-IN")}`
          : "",
      "Closing Balance Difference":
        minClosingBalanceDiff !== null && maxClosingBalanceDiff !== null
          ? `${minClosingBalanceDiff.toLocaleString("en-IN")} - ${maxClosingBalanceDiff.toLocaleString("en-IN")}`
          : minClosingBalanceDiff !== null
          ? `Minimum: ${minClosingBalanceDiff.toLocaleString("en-IN")}`
          : maxClosingBalanceDiff !== null
          ? `Maximum: ${maxClosingBalanceDiff.toLocaleString("en-IN")}`
          : "",
      "Date of Closing balance":
        fromDateOfClosingBalance && toDateOfClosingBalance
          ? [fromDateOfClosingBalance, toDateOfClosingBalance].join(" - ")
          : fromDateOfClosingBalance
          ? `${fromDateOfClosingBalance} Onwards`
          : toDateOfClosingBalance
          ? `Till ${toDateOfClosingBalance}`
          : "",
      "Date As of Last Balance Confirmed":
        fromDateAsOfLastBalanceConfirmed && toDateAsOfLastBalanceConfirmed
          ? [fromDateAsOfLastBalanceConfirmed, toDateAsOfLastBalanceConfirmed].join(" - ")
          : fromDateAsOfLastBalanceConfirmed
          ? `${fromDateAsOfLastBalanceConfirmed} Onwards`
          : toDateAsOfLastBalanceConfirmed
          ? `Till ${toDateAsOfLastBalanceConfirmed}`
          : "",
      "Balance Confirmation Document":
        downloadAvailable.yes && downloadAvailable.no
          ? "Yes, No"
          : downloadAvailable.yes
          ? "Yes"
          : downloadAvailable.no
          ? "No"
          : "",
      "Partner Ledgers":
        downloadAvailableLedger.yes && downloadAvailableLedger.no
          ? "Yes, No"
          : downloadAvailableLedger.yes
          ? "Yes"
          : downloadAvailableLedger.no
          ? "No"
          : "",
    });

    props.setRowsDataBalanceConfirmation(tempRowData);
    handleClose();
  };

  useEffect(() => {
    if (props.reFilter) {
      filterBalanceConfirmation();
      props?.setReFilter(false);
    }
    // eslint-disable-next-line
  }, [props.reFilter]);

  const clearAllAppliedFilters = () => {
    setSelectedCategory([]);
    setSelectedStatus([]);

    // All the number states reset
    setMinClosingBalance(null);
    setMaxClosingBalance(null);
    setMinClosingBalanceBp(null);
    setMaxClosingBalanceBp(null);
    setMinClosingBalanceDiff(null);
    setMaxClosingBalanceDiff(null);

    // All the Date states reset
    setFromDateOfClosingBalance(null);
    setToDateOfClosingBalance(null);
    setFromDateAsOfLastBalanceConfirmed(null);
    setToDateAsOfLastBalanceConfirmed(null);

    setPartnerNames([]);

    setDownloadAvailable({ yes: false, no: false });
    setDownloadAvailableLedger({ yes: false, no: false });

    props.setAppliedFilters({});

    handleClose();
    setTimeout(() => {
      props.setOpenBalanceConfirmationFilter(true);
      props.setRowsDataBalanceConfirmation(props.storeRowsDataBalanceConfirmation);
      props.setIsFilterAppliedBalanceConfirmation(false);
    }, 700);
  };

  return (
    <div>
      <Dialog
        open={props.openBalanceConfirmationFilter}
        aria-labelledby="BalanceConfirmationFilter"
        className="ledgerRequestFilter"
      >
        <DialogTitle className="dialog_header">
          <p className="fs_24">Apply Filter</p>
          <button
            className="delete ml_20"
            aria-label="close"
            onClick={() => {
              setPartnerNames(filterObj?.partnerNames || []);
              setSelectedCategory(filterObj?.category || []);
              setSelectedStatus(filterObj?.status || []);
              handleClose();
            }}
          />
        </DialogTitle>
        <DialogContent>
          <div>
            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">Partner Names :</div>
              <div className="w_400 d_flex category_textFeild_width_390 ">
                <TagsInputUniqueKeys
                  tags={partnerNames}
                  setTags={setPartnerNames}
                  className="partnerNamesAutocomplete"
                  fullWidth={true}
                  label=""
                  placeholder="Newline separated Names"
                  splitter="newline"
                  limitTags={3}
                  textFieldProps={{ multiline: true, maxRows: 4 }}
                  options={props.storeRowsDataBalanceConfirmation}
                  accessor={"businessPartnerName"}
                  uniqueKeyAccessor={"businessPartnerId"}
                />
              </div>
            </div>
            <div className="d_flex m_15 ">
              <div className="w_300 vertical_center_align fw_600">Category :</div>
              <div className="w_400 category_textFeild_width_390">
                <Autocomplete
                  // limitTags={3}
                  value={selectedCategory}
                  fullWidth={true}
                  onChange={(_, value) => {
                    setSelectedCategory(value);
                  }}
                  size="small"
                  multiple={true}
                  id="tags-outlined"
                  options={props.allCategories || []}
                  getOptionLabel={(option) => option}
                  // defaultValue={[top100Films[13]]}
                  filterSelectedOptions={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select category"
                      // placeholder="Favorites"
                    />
                  )}
                />
              </div>
            </div>
            <div className="d_flex m_15 ">
              <div className="w_300 vertical_center_align fw_600">Status :</div>
              <div className="w_400 category_textFeild_width_390">
                <Autocomplete
                  // limitTags={3}
                  value={selectedStatus}
                  fullWidth={true}
                  onChange={(_, value) => {
                    setSelectedStatus(value);
                  }}
                  size="small"
                  multiple={true}
                  id="tags-outlined"
                  options={BalanceConfirmationStatus || []}
                  getOptionLabel={(option) => option}
                  // defaultValue={[top100Films[13]]}
                  filterSelectedOptions={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select status"
                      // placeholder="Favorites"
                    />
                  )}
                />
              </div>
            </div>

            <div className="d_flex m_15 ">
              <div className="w_300 vertical_center_align fw_600">Balance Confirmation Document:</div>
              <div className="w_400 category_textFeild_width_390">
                <CheckboxGroup row={true} onChange={(_e, v) => setDownloadAvailable({ yes: v.yes, no: v.no })}>
                  <FormControlLabel control={<Checkbox checked={downloadAvailable.yes} name="yes" />} label="Yes" />
                  <FormControlLabel control={<Checkbox checked={downloadAvailable.no} name="no" />} label="No" />
                </CheckboxGroup>
              </div>
            </div>

            <div className="d_flex m_15 ">
              <div className="w_300 vertical_center_align fw_600">Partner Ledgers:</div>
              <div className="w_400 category_textFeild_width_390">
                <CheckboxGroup row={true} onChange={(_e, v) => setDownloadAvailableLedger({ yes: v.yes, no: v.no })}>
                  <FormControlLabel
                    control={<Checkbox checked={downloadAvailableLedger.yes} name="yes" />}
                    label="Yes"
                  />
                  <FormControlLabel control={<Checkbox checked={downloadAvailableLedger.no} name="no" />} label="No" />
                </CheckboxGroup>
              </div>
            </div>

            <NumberFieldFilter
              label="Closing Balance Own"
              minNumber={minClosingBalance}
              maxNumber={maxClosingBalance}
              setMinNumber={setMinClosingBalance}
              setMaxNumber={setMaxClosingBalance}
            />
            <NumberFieldFilter
              label="Closing Balance Partner"
              minNumber={minClosingBalanceBp}
              maxNumber={maxClosingBalanceBp}
              setMinNumber={setMinClosingBalanceBp}
              setMaxNumber={setMaxClosingBalanceBp}
            />
            <NumberFieldFilter
              label="Closing Balance Difference"
              minNumber={minClosingBalanceDiff}
              maxNumber={maxClosingBalanceDiff}
              setMinNumber={setMinClosingBalanceDiff}
              setMaxNumber={setMaxClosingBalanceDiff}
            />
            {props.historyConsumer && (
              <div className="d_flex m_15">
                <div className="w_300 vertical_center_align fw_600">Date of Email Initiation :</div>
                <div className="w_400 d_flex textFeild_width_185">
                  <TextField
                    className="mr_20"
                    label="From"
                    InputLabelProps={{ shrink: true }}
                    id="outlined-size-small"
                    size="small"
                    type="date"
                    value={fromDateOfMailInitiation || ""}
                    onChange={(e) => {
                      setFromDateOfMailInitiation(e.target.value);
                    }}
                  />
                  <TextField
                    label="To"
                    InputLabelProps={{ shrink: true }}
                    id="outlined-size-small"
                    size="small"
                    type="date"
                    value={toDateOfMailInitiation || ""}
                    onChange={(e) => {
                      setToDateOfMailInitiation(e.target.value);
                    }}
                  />
                </div>
              </div>
            )}
            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">Date of closing balance :</div>
              <div className="w_400 d_flex textFeild_width_185">
                <TextField
                  className="mr_20"
                  label="From"
                  InputLabelProps={{ shrink: true }}
                  id="outlined-size-small"
                  size="small"
                  type="date"
                  value={fromDateOfClosingBalance || ""}
                  onChange={(e) => {
                    setFromDateOfClosingBalance(e.target.value);
                  }}
                />
                <TextField
                  label="To"
                  InputLabelProps={{ shrink: true }}
                  id="outlined-size-small"
                  size="small"
                  type="date"
                  value={toDateOfClosingBalance || ""}
                  onChange={(e) => {
                    setToDateOfClosingBalance(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="d_flex m_15"></div>
          </div>
        </DialogContent>
        <DialogActions className="dialog_footer space_between">
          <div>
            <Button
              className="theme_outline_btn"
              onClick={() => {
                clearAllAppliedFilters();
              }}
            >
              Clear All
            </Button>
          </div>
          <div>
            <Button
              className="theme_btn"
              id="filterBalanceConfirmation_button"
              onClick={() => {
                filterBalanceConfirmation();
              }}
            >
              Apply
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const NumberFieldFilter = ({
  label,
  minNumber,
  setMinNumber,
  maxNumber,
  setMaxNumber,
}: {
  label: string;
  minNumber: number;
  setMinNumber: StateDispatch<number>;
  maxNumber: number;
  setMaxNumber: StateDispatch<number>;
}) => {
  return (
    <>
      <div className="d_flex m_15">
        <div className="w_300 vertical_center_align fw_600">{label}</div>
        <div className="w_400 d_flex textFeild_width_185">
          <MonetaryInput
            className="mr_20"
            label="Min"
            size="small"
            value={minNumber}
            setValue={(value) => {
              setMinNumber(value);
            }}
            returnNull={true}
          />
          <MonetaryInput
            label="Max"
            size="small"
            value={maxNumber}
            setValue={(value) => {
              setMaxNumber(value);
            }}
            returnNull={true}
          />
        </div>
      </div>
    </>
  );
};

export default BalanceConfirmationBetaFilter;

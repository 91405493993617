import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import { StateDispatch } from "../CommonLegacy/ReplyEmail";
import { NdAsyncButton } from "../MsmePartnerPortal/CommonComponents";
import $ from "../MsmePartnerPortal/PartnerPortal.module.scss";

interface CommonDialogProps {
  open: boolean;
  setOpen: StateDispatch<boolean>;
}
interface ApproveResponseDialogProps extends CommonDialogProps {
  partnerCount: number;
  approveAction: () => Promise<void>;
}

export const ApproveResponseDialog = ({ open, setOpen, partnerCount, approveAction }: ApproveResponseDialogProps) => {
  const [waiting, setWaiting] = React.useState(false);
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} classes={{ paper: $.BR_fix }} maxWidth="sm" fullWidth>
        <DialogTitle>Have you reviewed submitted response of {partnerCount ? partnerCount : ""} partners?</DialogTitle>
        <DialogContent className={$.flex_column} sx={{ py: 2, px: 3 }}>
          <Alert severity="info">
            Response submitted will be freezed and can't be edited. Make sure you have reviewed the partner's response.
          </Alert>
        </DialogContent>
        <DialogActions>
          <NdAsyncButton
            className={$.BR_fix}
            onClick={async () => {
              setWaiting(true);
              await approveAction();
              setOpen(false);
              setWaiting(false);
            }}
            color="primary"
            buttonComponent={Button}
          >
            Yes
          </NdAsyncButton>
          <Button
            className={$.BR_fix}
            onClick={() => {
              setOpen(false);
            }}
            color="primary"
            disabled={waiting}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface ResetStatusDialogProps extends CommonDialogProps {
  partnerCount: number;
  partnerName: string;
  resetAction: () => Promise<void>;
}

export const ResetStatusDialog = ({
  open,
  setOpen,
  partnerCount,
  partnerName,
  resetAction,
}: ResetStatusDialogProps) => {
  const [waiting, setWaiting] = React.useState(false);

  return (
    <>
      <Dialog open={open} classes={{ paper: $.BR_fix }} maxWidth="md" fullWidth>
        <DialogTitle textTransform={"uppercase"} className="textOverflow_hidden d_flex">
          Have you reviewed{"  "}
          <h2
            style={{ font: "inherit", maxWidth: 400, display: "inline-block", paddingLeft: 6 }}
            className="textOverflow_hidden"
          >
            {partnerName ? partnerName : `all of ${partnerCount} partner`}
          </h2>
          's response?
        </DialogTitle>
        <DialogContent className={$.flex_column} sx={{ py: 2, px: 3 }}>
          <Alert severity="warning" sx={{ "*": { fontFamily: "inherit" } }}>
            This action will reset the communication status of the selected partner and cannot be reverted
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button
            className={$.BR_fix}
            onClick={() => {
              setOpen(false);
            }}
            color="primary"
            disabled={waiting}
          >
            Cancel
          </Button>
          <NdAsyncButton
            variant="contained"
            className={$.BR_fix}
            onClick={async () => {
              setWaiting(true);
              await resetAction();
              setOpen(false);
              setWaiting(false);
            }}
            color="warning"
            buttonComponent={Button}
          >
            Reset
          </NdAsyncButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface UpdateResponseDialogProps extends CommonDialogProps {
  link?: string;
}

export const UpdateResponseDialog = ({ open, setOpen, link }: UpdateResponseDialogProps) => {
  const [waiting, setWaiting] = React.useState(false);
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} classes={{ paper: $.BR_fix }} maxWidth="sm" fullWidth>
        <DialogTitle>Are you sure you want to Update Response?</DialogTitle>
        <DialogContent className={$.flex_column} sx={{ py: 2, px: 3 }}>
          <Alert severity="warning">You are about to update response manually in partner portal</Alert>
        </DialogContent>
        <DialogActions>
          <Button
            className={$.BR_fix}
            onClick={() => {
              setWaiting(true);
              window.open(link, "_blank");
              setOpen(false);
              setWaiting(false);
            }}
            color="primary"
          >
            Yes
          </Button>
          <NdAsyncButton
            className={$.BR_fix}
            variant="contained"
            onClick={async () => {
              setOpen(false);
            }}
            disabled={waiting}
          >
            No
          </NdAsyncButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface UpdateEmailTemplateDialogProps extends CommonDialogProps {
  noAction?: () => Promise<void>;
  yesAction?: () => Promise<void>;
}

export const UpdateEmailTemplateDialog = ({ open, setOpen, yesAction, noAction }: UpdateEmailTemplateDialogProps) => {
  const [waiting, setWaiting] = React.useState(false);
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} classes={{ paper: $.BR_fix }} maxWidth="sm" fullWidth>
        <DialogTitle>Update E-mail template</DialogTitle>
        <DialogContent className={$.flex_column} sx={{ py: 2, px: 3 }}>
          <Alert severity="warning">Do you want to update the email template to use it in future?</Alert>
        </DialogContent>
        <DialogActions>
          <NdAsyncButton
            className={$.BR_fix}
            variant="text"
            buttonComponent={Button}
            color="error"
            onClick={async () => {
              setWaiting(true);
              await noAction();
              setOpen(false);
              setWaiting(false);
            }}
            disabled={waiting}
          >
            No
          </NdAsyncButton>
          <NdAsyncButton
            className={$.BR_fix}
            variant="contained"
            onClick={async () => {
              setWaiting(true);
              await yesAction();
              setOpen(false);
              setWaiting(false);
            }}
            color="primary"
          >
            Yes
          </NdAsyncButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
